import React, { Component } from 'react';
import { withRouter } from "react-router";
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import env from "../../configs/config"
import $ from "jquery";
import * as msal from 'msal';
import { PublicClientApplication } from "@azure/msal-browser";
import { observer, inject } from "mobx-react";

//todo msal config can be moved to seprate files
const msalConfig = {
    auth: {
        clientId: env["clientId"],
        authority: "https://login.microsoftonline.com/common",
        knownAuthorities: [],
        cloudDiscoveryMetadata: "",
        redirectUri: env["redirectUri"],
        // postLogoutRedirectUri: "enter_postlogout_uri_here",
        navigateToLoginRequestUrl: true,
        clientCapabilities: ["CP1"],
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case msal.LogLevel.Error:
                        console.error(message);
                        return;
                    case msal.LogLevel.Info:
                        console.info(message);
                        return;
                    case msal.LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case msal.LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                }
            },
        },
    },
};
const myMSALObj = new PublicClientApplication(msalConfig);
// Add here the scopes that you would like the user to consent during sign-in
const loginRequest = {
    scopes: ["User.Read"],
};

// Add here the scopes to request when obtaining an access token for MS Graph API
// const tokenRequest = {
//     scopes: ["User.Read", "Mail.Read"],
//     forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
// };

const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/messages",
};

myMSALObj
    .handleRedirectPromise()
    .then((resp) => {
        if (resp !== null) {
            username = resp.account.username;
            localStorage.setItem("userName", username);
        } else {
            //toast.error('Access denied. Please contact administrator', 5);
        }
    })
    .catch((err) => {
        console.error(err);
    });

var username = "";
@withRouter
@inject("applicationStore")
@observer
class LoginWithMS extends Component {

    constructor(props) {
        super();
        this.state = {
            ApplicationId: props.ApplicationId
        };
    }

    componentDidMount = () => {
        const { history } = this.props;

        if (
            this.props.applicationStore.isExternalLoggedIn() &&
            !this.props.applicationStore.isLoggedIn() &&
            env["ssocontinueslogin"]
        ) {
            this.ConnectAD();
        } else if (this.props.applicationStore.isLoggedIn()) {
            history.push({ pathname: "/welcome" });
        }
    }

    ConnectAD = (e) => {
        this.setState({
            loading: true
        });
        this.props.applicationStore.loading = true;
        if (e) {
            e.preventDefault();
        }
        this.signIn();
    };

    getTokenPopup(request) {
        /**
         * See here for more info on account retrieval:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
         */
        request.account = myMSALObj.getAccountByUsername(username);

        return myMSALObj.acquireTokenSilent(request).catch((error) => {
            console.warn(
                "silent token acquisition fails. acquiring token using popup"
            );
            if (error instanceof msal.InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return myMSALObj
                    .acquireTokenPopup(request)
                    .then((tokenResponse) => {
                        // console.log(tokenResponse);
                        return tokenResponse;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                console.warn(error);
            }
        });
    }

    SyncWithIdentityServer(data, accessToken, self) {
        //console.log(data);

        const { history, applicationStore } = self.props;
        applicationStore
            .signInWithAD(data, accessToken, this.props.ApplicationId)
            .then(function () {
                applicationStore.loading = false;
                toast.success("Signed in successfully.", 5);
                $(document).trigger("user-logged-in");
                localStorage.setItem("ExternalLogin", true);
                history.push({ pathname: "/welcome" });
            })
            .fail(
                function (data) {
                    applicationStore.loading = false;
                    toast.error(
                        "Access denied. Please contact the administrator at mets.kramer@strategicevolutions.ca",
                        5
                    );

                    //log out Authenticated user as well
                    setTimeout(function () {
                        const logoutRequest = {
                            account: myMSALObj.getAccountByUsername(username),
                        };
                        myMSALObj.logout(logoutRequest);
                    }, 4000);
                }
            );
    }

    seeProfile() {
        this.getTokenPopup(loginRequest)
            .then((response) => {
                this.callMSGraph(
                    graphConfig.graphMeEndpoint,
                    response.accessToken,
                    this.SyncWithIdentityServer
                );
            })
            .catch((error) => {
                console.error(error);
            });
    }

    callMSGraph(endpoint, accessToken, callback) {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers,
        };

        // console.log("request made to Graph API at: " + new Date().toString());

        fetch(endpoint, options)
            .then((response) => response.json())
            .then((response) => callback(response, accessToken, this))
            .catch((error) => console.log(error));
    }

    handleResponse(resp) {
        if (resp !== null) {
            username = resp.account.username;
            this.seeProfile();
        } else {
            toast.error(
                "Access denied. Please contact the administrator at mets.kramer@strategicevolutions.ca",
                5
            );
        }
    }

    signIn() {
        var self = this;
        myMSALObj
            .loginPopup(loginRequest)
            .then((resp) => {
                if (resp !== null) {
                    username = resp.account.username;
                    self.seeProfile();
                } else {
                    toast.error(
                        "Access denied. Please contact the administrator at mets.kramer@strategicevolutions.ca",
                        5
                    );
                }
            })
            .catch((error) => {
                toast.error(error, 5);
            });
    }

    render() {
        let loginWithMicrosoft = "";
        if (env["sso"]) {
            loginWithMicrosoft = (
                <Button color="outline-primary" size="sm" block className="mt-2" onClick={this.ConnectAD}>
                    Microsoft
                </Button>
            );
        } else {
            loginWithMicrosoft = "";
        }
        return (
            <div>
                {loginWithMicrosoft}
            </div>
        )
    }
}


export default LoginWithMS;