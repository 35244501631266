export default {
    Dashboard: "translation_found",
    Login: "translation_found",
    "Available Assets": "translation_found",
    "Leads To Action": "translation_found",
    "Assets Without Target Date": "translation_found",
    "Open Activities": "translation_found",
    "Companies need followup": "translation_found",
    "Contact need followup": "translation_found",
    "Forecast Replacement Sales": "translation_found",
    "Sales Opportunity Forecast": "translation_found",
    "Open Leads": "translation_found",
    "Missed Leads": "Missed Leads",
    "Add New": "translation_found",
    "Recent Activities (Last 60 days)": "translation_found",
    "Lead Description": "translation_found",
    Company: "translation_found",
    Model: "translation_found",
    Status: "translation_found",
    Date: "translation_found",
    Subject: "translation_found",
    "Activity Date": "translation_found",
    Companies: "translation_found",
    Contacts: "translation_found",
    Locations: "translation_found",
    Assets: "translation_found",
    Activities: "translation_found",
    Leads: "translation_found",
    Admin: "translation_found",
    Profile: "translation_found",
    Reports: "translation_found",
    Advertisements: "translation_found",
    Inventory: "translation_found",
    Marketing: "translation_found",
    Calendar: "translation_found",
    "Add/Edit Activity": "translation_found",
    "Activity Id": "translation_found",
    Duration: "translation_found",
    Description: "translation_found",
    Location: "translation_found",
    "Activity Type": "translation_found",
    "Activity Status": "translation_found",
    Save: "translation_found",
    "Add/Edit Lead": "translation_found",
    Main: "translation_found",
    "Short Desc": "translation_found",
    "Long Desc": "translation_found",
    "Lead Value": "translation_found",
    "Lead Status": "translation_found",
    Reason: "translation_found",
    Likelihood: "translation_found",
    Note: "translation_found",
    "Lead Type": "translation_found",
    "Target Decision Date": "translation_found",
    "Followup Date": "translation_found",
    Contact: "translation_found",
    Responsibility: "translation_found",
    "All Columns": "translation_found",
    Address: "translation_found",
    Type: "translation_found",
    Ranking: "translation_found",
    Submit: "translation_found",
    Find: "translation_found",
    "Add Company": "translation_found",
    "Merge Companies": "translation_found",
    "Last Activity Date": "translation_found",
    "Company #": "translation_found",
    Action: "translation_found",
    "First Name": "translation_found",
    "Last Name": "translation_found",
    Email: "translation_found",
    LinkedIn: "translation_found",
    Mobile: "translation_found",
    "Contact Name": "translation_found",
    "Contact Email": "translation_found",
    "Contact Phone": "translation_found",
    Featured: "translation_found",
    Yes: "translation_found",
    No: "translation_found",
    Title: "translation_found",
    "Contact Type": "translation_found",
    "Office Phone": "translation_found",
    "Home Phone": "translation_found",
    Edit: "translation_found",
    Move: "translation_found",
    "Disconnect From Company": "translation_found",
    DisconnectWarn: "translation_found",
    Press: "translation_found",
    OK: "translation_found",
    "to continue": "translation_found",
    "Disconnect Contact?": "translation_found",
    "Show Active": "translation_found",
    "Company Name": "translation_found",
    Address1: "translation_found",
    "Postal Code": "translation_found",
    Country: "translation_found",
    Province: "translation_found",
    Phone: "translation_found",
    Fax: "translation_found",
    Types: "translation_found",
    City: "translation_found",
    Time: "translation_found",
    Reminder: "translation_found",
    "Change User": "translation_found",
    "Change Date": "translation_found",
    DeleteRecordWarn: "translation_found",
    DeleteRecord: "translation_found",
    "Lead Id": "translation_found",
    "Quote Status": "translation_found",
    Followup: "translation_found",
    "Quote Value": "translation_found",
    "Quote #": "translation_found",
    Advertiser: "translation_found",
    "Advertised Price": "translation_found",
    "Advertised Currency": "translation_found",
    "Start Date": "translation_found",
    "End Date": "translation_found",
    "Main Picture": "translation_found",
    "Asset Type": "translation_found",
    Make: "translation_found",
    "Serial Number": "translation_found",
    Currency: "translation_found",
    Expired: "translation_found",
    Enabled: "translation_found",
    "Sort Order": "translation_found",
    Refresh: "translation_found",
    Refreshing: "translation_found",
    Year: "translation_found",
    SMU: "translation_found",
    "Asset Desc": "translation_found",
    "Target Sell Date": "translation_found",
    "Categorization Breadcrumb": "translation_found",
    "Add Asset": "translation_found",
    "PDF Summary": "translation_found",
    "Mailchimp Settings": "translation_found",
    "Synchronization Log": "translation_found",
    Connect: "translation_found",
    Audiences: "translation_found",
    "Save Settings": "translation_found",
    "Sync with": "translation_found",
    Created: "translation_found",
    Delete: "translation_found",
    "Add Location": "translation_found",
    SchemaId: "translation_found",
    Schema: "translation_found",
    "User Name": "translation_found",
    "Edit Schema": "translation_found",
    "Add/Edit Schema": "translation_found",
    "Copy To": "translation_found",
    "Remember me": "translation_found",
    "Unit Number": "translation_found",
    "Asset Status": "translation_found",
    Serial: "translation_found",
    "Serial #": "translation_found",
    "Add Component": "translation_found",
    "Asset Type Categorization": "translation_found",
    "Edit categorization": "translation_found",
    "Sold Date": "translation_found",
    Birthday: "translation_found",
    "Purchase Date": "translation_found",
    "Replacement Value": "translation_found",
    "SMU Date": "translation_found",
    Category: "translation_found",
    "Last SMU Date": "translation_found",
    "Date Range": "translation_found",
    "Transaction Type": "translation_found",
    Vendor: "translation_found",
    Billable: "translation_found",
    Amount: "translation_found",
    "Vendor Inv #": "translation_found",
    "Add Expense": "translation_found",
    "Re-calculate forecast": "translation_found",
    Margin: "translation_found",
    "Purchase Price": "translation_found",
    "Total Expenses": "translation_found",
    "Total Cost": "translation_found",
    "Total Income": "translation_found",
    "Forecast Profit": "translation_found",
    "Asking Price": "translation_found",
    "Picture Id": "translation_found",
    "Select File": "translation_found",
    Picture: "translation_found",
    "Picture Name": "translation_found",
    "Email Customer": "translation_found",
    "Email": "translation_found",
    "Delete Selected Pic": "translation_found",
    "Add Picture": "translation_found",
    "Bulk Upload": "translation_found",
    "Choose Contact to send": "translation_found",
    Upload: "translation_found",
    To: "translation_found",
    Attachments: "translation_found",
    "Select Template": "translation_found",
    None: "translation_found",
    Username: "translation_found",
    Password: "translation_found",
    Priority: "translation_found",
    High: "translation_found",
    Normal: "translation_found",
    "Send Email": "translation_found",
    Body: "translation_found",
    Exclusions: "translation_found",
    Inclusions: "translation_found",
    "Expiration Type": "translation_found",
    "Warranty Type": "translation_found",
    "Add Warranty": "translation_found",
    ID: "translation_found",
    Brands: "translation_found",
    "Quick add a": "translation_found",
    Brand: "translation_found",
    "to master list": "translation_found",
    "Add Comment": "translation_found",
    "Parent Company": "translation_found",
    "Address 1": "translation_found",
    "Address 2": "translation_found",
    "State/Prov": "translation_found",
    "Zip/Postal Cd": "translation_found",
    "Customer #": "translation_found",
    "Pref Lang": "translation_found",
    "Comp Type": "translation_found",
    Industry: "translation_found",
    Website: "translation_found",
    "Contact Freq": "translation_found",
    SalesRep: "translation_found",
    Ownership: "translation_found",
    Analysis: "translation_found",
    "Add Contact": "translation_found",
    "Add New Contact": "translation_found",
    "Choose Company to move to": "translation_found",
    "Unit ID": "translation_found",
    "Target Date": "translation_found",
    Price: "translation_found",
    "View Document": "translation_found",
    "Add Lead": "translation_found",
    "Quote Number": "translation_found",
    "Location Name": "translation_found",
    "State/Province": "translation_found",
    Latitude: "translation_found",
    Longitude: "translation_found",
    PostalCode: "translation_found",
    "Loc Types Count": "translation_found",
    "Loc Contacts Count": "translation_found",
    "Change Password": "translation_found",
    "Password guideline": "translation_found",
    Salutation: "translation_found",
    "Reports To": "translation_found",
    "Mobile Phone": "translation_found",
    "Sales Opportunity By Industry": "translation_found",
    "Sign out": "translation_found",
    "Phone Number": "translation_found",
    View: "translation_found",
    "Alternate Price": "translation_found",
    "Alternate Currency": "translation_found",
    "Sales Manager": "translation_found",
    Settings: "translation_found",
    "Brands Represented": "translation_found",
    "Asset Info": "translation_found",
    Components: "translation_found",
    Warranties: "translation_found",
    Expenses: "translation_found",
    Pictures: "translation_found",
    Documents: "translation_found",
    Remove: "translation_found",
    Options: "translation_found",
    "TransDate": "translation_found",
    "Vendor Inv. #": "translation_found",
    "Add/Edit Advertisement": "translation_found",
    "Add/Edit Expense": "translation_found",
    "Add/Edit Picture": "translation_found",
    "Add/Edit Warranty": "translation_found",
    "Add/Edit Document": "translation_found",
    "Add/Edit Location": "translation_found",
    "Save and Close": "translation_found",
    "Save and Add New": "translation_found",
    "Select Documents": "translation_found",
    Print: "translation_found",
    "Add Advertisement": "translation_found",
    "Find Existing Customer for New Lead": "translation_found",
    "Move Asset": "translation_found",
    "Select and Add Lead": "translation_found",
    "Choose Company to Add Lead to: ": "translation_found",
    "Provide a Reason": "translation_found",
    "No Company Selected": "translation_found",
    Comments: "translation_found",
    Cancel: "translation_found",
    t_found_bro: "translation_found",
    "Geo Lookup": "translation_found",
    Street: "translation_found",
    Postal: "translation_found",
    State: "translation_found",
    "Pick Location": "translation_found",
    "Last edited by": "translation_found",
    "Location Count": "translation_found",
    "Contacts Count": "translation_found",
    "Leads Count": "translation_found",
    "Activities Count": "translation_found",
    "Sales Rep": "translation_found",
    "Add Activity": "translation_found",
    "Priority Leads": "translation_found",
    "Priority Assets": "translation_found",
    "Leads to followup": "translation_found",
    "Lead Type Categorization": "translation_found",
    "Categorize Lead": "translation_found",
    "Hide Matching Assets": "translation_found",
    "Show Matching Assets": "translation_found",
    "Table Name": "translation_found",
    "Main Tab Location": "translation_found",
    "There are no": "translation_found",
    activities: "translation_found",
    recorded: "translation_found",
    Address2: "translation_found",
    "Location Types": "translation_found",
    "Show Only Inventory": "translation_found",
    "Setting Id": "translation_found",
    "Setting Name": "translation_found",
    "Setting Value": "translation_found",
    "Available To Admin": "translation_found",
    "User Added Field": "translation_found",
    "Edit Setting": "translation_found",
    "Add Setting": "translation_found",
    "Add New Document": "translation_found",
    "File Name": "translation_found",
    "Document Description": "translation_found",
    "Document Type": "translation_found",
    Version: "translation_found",
    "Upload Date": "translation_found",
    "All Version": "translation_found",
    Loading: "translation_found",
    "Select Document": "translation_found",
    "Find Existing Customer for New Asset": "translation_found",
    "Select and Add Asset": "translation_found",
    "Choose Company to Add Lead to": "translation_found",
    "Choose Company to Add Asset to": "translation_found",
    "Phone Call": "translation_found",
    "Site Visit": "translation_found",
    "Activity Date & Time": "translation_found",
    "Created by": "translation_found",
    "Changed by": "translation_found",
    "Asset Details": "translation_found",
    items: "translation_found",

    "Price Lookup": "translation_found",
    "Categorize Asset": "translation_found",
    "Create Replacement Lead": "translation_found",
    "Categorize this asset": "translation_found",
    "Save and Reload": "translation_found",
    "Component Info": "translation_found",
    "Updated Date": "translation_found",
    "Document association": "translation_found",
    "Associated with": "translation_found",
    "Hours": "translation_found",
    "Asset Categorization Text1": "translation_found",
    "Asset Categorization Text2": "translation_found",
    "Tier1": "translation_found",
    "Tier2": "translation_found",
    "Tier3": "translation_found",
    "Tier4": "translation_found",
    "Used Equipment Guide": "transltation_found",
    "Market Price Calculator powered by": "translation_found",

    "MinYear": "translation_found",
    "MaxYear": "translation_found",
    "MinSMU": "translation_found",
    "MaxSMU": "translation_found",
    "Delete Picture": "translation_found",
    "Download Picture": "translation_found",
    "Upcoming Asset Replacements (6 Month)": "translation_found",
    "Add New Component": "translation_found",
    "Upload Multiple": "translation_found",
    "Owner": "translation_found",
    "Select a Category": "translation_found",
    "Log Id": "translation_found",
    "Api Key": "translation_found",
    "ListId": "translation_found",
    "Successful": "translation_found",
    "User Name": "translation_found",
    "Create Date": "translation_found",
    "Synchronize Contacts": "translation_found",
    "Transaction Date": "translation_found",
    "Merge Companies": "translation_found",
    "Merge Into": "translation_found",
    "Merge From": "translation_found",
    "No Data": "translation_found",
    "API Key": "translation_found",
    "Asset Categorization": "translation_found",
    "Add": "translation_found",

    "Quotes": "translation_found",
    "Quote #": "translation_found",
    "Revision #": "translation_found",
    "Revision #": "translation_found",
    "Quote Date": "translation_found",
    "Add Quote": "translation_found",
    "Lead Subject": "translation_found",
    "Equipment Desc": "translation_found",
    "Quote Type": "translation_found",
    "Expiration Date": "translation_found",
    "Flat Price": "translation_found",
    "Added Tax": "translation_found",
    "INCO Term": "translation_found",
    "Template": "translation_found",
    "Package/Options": "translation_found",
    "Save Current Revision": "translation_found",
    "Save As New Revision": "translation_found",
    "Quote Header Text": "translation_found",

    "Segment #": "translation_found",
    "Segment Type": "translation_found",
    "Add New Segment": "translation_found",
    "Segments": "translation_found",
    "Machine Total": "translation_found",
    "Other Total": "translation_found",
    "Taxable": "translation_found",
    "Condition": "translation_found",
    "Features": "translation_found",
    "Pictures ": "translation_found ",
    "Short Description": "translation_found",

    "Asset Details": "translation_found",
    "Segment Type": "translation_found",
    "Current SMU": "translation_found",
    "Show Condition": "translation_found",
    "Show Features": "translation_found",

    "Item Type": "translation_found",
    "Quantity": "translation_found",
    "Cost": "translation_found",
    "Total": "translation_found",
    "ItemNumber": "translation_found",
    "Sell Price": "translation_found",
    "Add New Item": "translation_found",
    "Item Number": "translation_found",
    "Source": "translation_found",
    "Items": "translation_found",
    "Move to Segment": "translation_found",
    "Segment Desc": "translation_found",

    "Save and Close": "translation_found",
    "Feature": "translation_found",
    "Show on Quote": "translation_found",
    "Revision": "translation_found",
    "Add New Feature": "translation_found",
    "View as HTML": "translation_found",

    "Leads without Company": "translation_found",

    "Won Leads": "translation_found",
    "Leads Forecast": "translation_found",
    "Delivery Fees": "translation_found",
    "Delivery Cost NOT included": "translation_found",
    "State/Province": "translation_found",
    "Select Pictures": "translation_found",
    "Advertise": "translation_found",
    "Organization": "translation_found",
    "Frequency": "translation_found",
    "Frequency Unit": "translation_found",

    "County": "translation_found",

    "Light Mode": "translation_found",
    "Dark Mode": "translation_found",
    "Sales Analysis": "translation_found",
    "Brand Represented": "translation_found",

    "Add/Edit Folder": "translation_found",
    "Select Folder": "translation_found",
    "Folder": "translation_found",
    "Folder Id": "translation_found",
    "Folder Name": "translation_found",
    "Sequence": "translation_found",
    "Active": "translation_found",
    "Move to Folder": "translation_found",
    "AltText": "translation_found",
    "Caption": "translation_found",

    "Inspections": "translation_found",
    "Work Orders": "translation_found",
    "Service Reports": "translation_found",
    "Backlog": "translation_found",
    "Backlog Item": "translation_found",
    "Backlog Description": "translation_found",
    "Target Inpsection Date": "translation_found",
    "Inspection Type": "translation_found",
    "Technician": "translation_found",
    "Approval Required": "translation_found",

    "Hold Machine": "translation_found",
    "Release Machine": "translation_found",
    "Approve": "translation_found",
    "Close": "translation_found",

    "Area": "translation_found",
    "Task": "translation_found",
    "Value": "translation_found",
    "Comment": "translation_found",
    "Approval": "translation_found",
    "Add to Backlog": "translation_found",
    "Notes": "translation_found",

    "Inspection Images": "translation_found",
    "Task Images": "translation_found",
    "TaskID": "translation_found",
    "In Supervisor Review": "translation_found",
    "Open Inspection": "translation_found",
    "Recent Inspections": "translation_found",
    "Add Video": "translation_found",
    "Name": "translation_found",

    "Item ID": "translation_found",
    "Logged Date": "translation_found",
    "Task Id": "translation_found",
    "Add/Edit Bcklog": "translation_found",

    "Add All Advertisers": "translation_found",
    "Select Picture": "translation_found"
};
