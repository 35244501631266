import React from 'react';
import { Nav } from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';

const TopNavRightSideNavItem = () => {
    return (
        <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
            <ProfileDropdown />
            &nbsp;&nbsp;&nbsp;
            <span className="pt-navbar-divider h6">|</span>
            &nbsp;
        </Nav>
    );
};

export default TopNavRightSideNavItem;