import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Card, CardBody, Button, Spinner } from 'reactstrap';
import logo from './logo-v.png';
@inject('applicationStore')
@observer
class ModuleCard extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  handleClick = () => {
    this.props.applicationStore.connectDB();
  };

  render() {
    return (
      <Card className="card-span h-00 mt-6">
        <div className="card-span-img">
          <img style={{ backgroundColor: 'black', borderRadius: '5px' }} className="fs-4" height="50px" src={logo} />
        </div>
        <CardBody className="pt-6 pb-4">
          {this.props.applicationStore.accessToken == null ? (
            <Spinner animation="border" variant="primary" />
          ) : !this.props.applicationStore.accessToken ? (
            <div>
              <h4 className="pt-4 pb-2">Click below to connect with Quick Book</h4>
              <Button color={'primary'} onClick={this.handleClick} outline>
                <h5>{this.props.module}</h5>
              </Button>
            </div>
          ) : (
            <div>
              <h4 className="pt-4 pb-2">
                Congratulation! You are successfully Connected to&nbsp;
                {this.props.applicationStore.settingKey?.Description || 'QuickBooks Online'}
              </h4>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default ModuleCard;
