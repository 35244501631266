import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { hot } from "react-hot-loader/root";
import { Provider } from 'mobx-react';
import { configure } from "mobx"

configure({
  useProxies: "never",
  enforceActions: "never"
})

//Language support imports please do not remove
import counterpart from 'counterpart';
import en_GB from "./languages/en-GB";
import en_US from "./languages/en-US";
import fr_FR from "./languages/fr-FR";
import corporate_en_GB from "./languages/corporate-en-GB";
import TranslateFunction from './customeComponents/language';

//imports for spinner and image please do not remove
import $ from "jquery";
import { pleaseWait } from 'please-wait';
import config from './configs/config';
import 'please-wait/build/please-wait.css';

import sessionTracker from './utils/sessionTracker';
import globalStore from './apiStores/application/globalStore';
import applicationStore from './apiStores/application/applicationStore';
import localStorageStore from './apiStores/application/localStorageStore';


const Stores = {
  applicationStore,
  sessionTracker,
  globalStore,
  localStorageStore,
};

$(document).ready(function () {
  $(document).bind('user-logged-in', function () {
    initSettingDataStore();
  });
});

function initPleaseWait() {
  window.loading_screen = pleaseWait({
    logo: 'logo-v-148x142.png',
    backgroundColor: config.THEME['@primary-color'],
    loadingHtml: `<p class='please-wait-loading-message'>The application is getting ready ...</p>
    <div class="sk-folding-cube" style='margin-top: -0.5%'>
      <div class="sk-cube1 sk-cube"></div>
      <div class="sk-cube2 sk-cube"></div>
      <div class="sk-cube4 sk-cube"></div>
      <div class="sk-cube3 sk-cube"></div>
    </div>`
  });
}

var firstRun = true;
function initSettingDataStore() {
  if (firstRun && applicationStore.isLoggedIn()) {
    initPleaseWait();
    setTimeout(() => {
      window.loading_screen.finish();
    }, 1000);
    firstRun = false;
  }
}

counterpart.registerTranslations('en-US', en_US);
counterpart.registerTranslations('fr-FR', fr_FR);
counterpart.registerTranslations('corporate-en-GB', corporate_en_GB);
counterpart.setLocale('en-US');


const render = () =>
  ReactDOM.render(
    <Main>
      <Provider {...Stores}>
        <App />
      </Provider>
    </Main>,
    document.getElementById('main')
  );

render(hot(App));



