
import localStorageStore from '../apiStores/application/localStorageStore';

class AuthHeaders {
    getToken() {
        return { 'Authorization': 'Bearer ' + localStorageStore.getTokenE };
    }
}


export default AuthHeaders;