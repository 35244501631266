//https://codeburst.io/react-router-v4-unofficial-migration-guide-5a370b8905a
import React, { Component } from 'react';
import $ from 'jquery';
import { observer, inject } from 'mobx-react';
import globalStore from '../apiStores/application/globalStore';

export var Restricted = BaseComponent => inject("applicationStore")(observer(class extends Component {
	displayName = "Restricted";
	componentWillMount() {
		this.checkAuthentication(this.props);
		this.checkDefaultModule(localStorage.getItem('defaultModule'), this.props);
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.location !== this.props.location) {
			// this.checkAuthentication(nextProps);
			// this.checkDefaultModule(localStorage.getItem('defaultModule'), nextProps);
		}
	}
	checkAuthentication(params) {
		const { history } = params;
		if (!this.props.applicationStore.isLoggedIn()) {
			history.replace({ pathname: '/login' });
		}
		else {
			$(document).ready(function () {
				$(document).trigger("user-logged-in");
			});
		}
	}

	checkDefaultModule(module, params) {
		const { history } = params;
		if (module == "null") {
			history.replace({ pathname: '/welcome' });
		}
	}

	render() {
		var base = <BaseComponent {...this.props} />;
		return base;
	}
}))