import { observable, action, makeObservable } from 'mobx';

/*eslint no-use-before-define: 0*/
class GlobalStore {

	@observable loading;

	constructor() {
		makeObservable(this)
	}

}

const globalStore = new GlobalStore();

export default globalStore;