//var $ = require('jquery');
//let envParam = $.trim(process.env.REACT_APP_ENVIRONMENT);
//https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

var envParam =
  process.env.REACT_APP_ENVIRONMENT !== undefined
    ? process.env.REACT_APP_ENVIRONMENT.trim()
    : process.env.REACT_APP_ENVIRONMENT;   

if (envParam === "SE") {
  module.exports = require("./config.se");
} else {
  module.exports = require("./config.local");
}