// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

const env = {
  // APP_BASE_URL: "https://dev.vizybility.net",
  // WEBAPI_BASE_URL: 'https://crm.api.vizybility.net',
  WEBAPI_BASE_URL: 'https://vizybility-api-dev.azurewebsites.net',

  //WEBAPI_BASE_URL: 'https://crm-web-webapi-dev.azurewebsites.net',
  //WEBAPI_BASE_URL: "https://crm-web-webapi-staging.azurewebsites.net",
  sso: true,
  ssocontinueslogin: true,
  DEVTOOLS_ENABLED: true,
  APPLICATION_ID: '7E84E687-8B74-465F-87F4-835553B75E17',
  OWNER: 'LOCAL',
  // STS_SIGNIN_URL: 'http://localhost:5000/connect/token',
  STS_SIGNIN_URL: 'https://viz-security-token-service-dev.azurewebsites.net/connect/token',
  STS_CLIENT_ID: 'viz-auth-serv-client',
  STS_CLIENT_SECRET: 'mmAm#GKxXeyz7rtP8htzU8r5y8kdg0YloqlSfH9#Vo9uSbq7zFZaIvvP4EE@Dh',
  STS_SCOPE: 'viz-crm-web-api-resource',
  SAS_TOKEN:
    '?sv=2019-02-02&ss=bfqt&srt=sco&sp=rwdlacup&se=2025-01-01T04:59:00Z&st=2020-01-01T05:00:00Z&spr=https&sig=S0KAsoMYqP1AFCfXE%2FDiwHOrSsbuqE8MdrhZOZtQzdo%3D',
  STORAGE_DIRECTORY_NAME: 'Dev',
  clientId: '247aa0e4-9396-48fb-bec9-728fab26df58',
  redirectUri: 'http://localhost:3000/',
  FIREBASE: {
    instance: 'DMSDb_local',
  },
  SETTINGS: {
    opportunitiesErpTabTab: { enabled: true },
  },
  MODULES: {
    advertise: { enabled: true },
    expense: { defaultCurrency: 'CAD' },
    expenseReportPdfView: { enabled: true },
    inventoryExportPdf: { enabled: true },
    mailchimp: { enabled: true },
  },
  THEME: {
    '@primary-color': '#fece02',
    '@link-color': '#fece02',
    '@font-size-base': '14px',
    '@font-family': 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    '@line-height-base': '1.0',
    '@border-radius-base': '8px',
  },
};

module.exports = env;
