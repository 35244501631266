import React, { Fragment, Component } from 'react';
import { withRouter } from "react-router";
import { observer, inject } from "mobx-react";
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Card, CardBody, Label } from 'reactstrap';
import Divider from '../../components/common/Divider';
import env from "../../configs/config"
import Section from '../../components/common/Section';
import SimpleReactValidator from 'simple-react-validator';
import LoginWithMS from './LoginWithMS';
import LoadingOverlay from 'react-loading-overlay';
import $ from "jquery";

@withRouter
@inject("applicationStore")
@observer
class Login extends Component {

    constructor(props) {
        super();
        this.state = {
            _username: '',
            _password: '',
            _remember: true,
            ApplicationId: '',
        };
        this.validator = new SimpleReactValidator();
    }

    componentDidMount = () => {
        const { history } = this.props;

        if (
            this.props.applicationStore.isExternalLoggedIn() &&
            !this.props.applicationStore.isLoggedIn() &&
            env["ssocontinueslogin"]
        ) {
            // ConnectAD();
        } else if (this.props.applicationStore.isLoggedIn()) {
            history.push({ pathname: "/welcome" });
        }
    }

    componentDidUpdate = () => {
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { history } = this.props;
        let _this = this;
        if (this.validator.allValid()) {
            this.props.applicationStore.loading = true;
            this.props.applicationStore
                .login(this.state._username, this.state._password, this.state.ApplicationId)
                .then(function () {
                    toast.success("Signed in successfully.", 5);
                    _this.props.applicationStore.loading = false;
                    $(document).trigger("user-logged-in");
                    setTimeout(() => {
                        history.push({ pathname: "/welcome" });
                    }, 2000);
                })
                .fail(
                    function (data) {
                        _this.props.applicationStore.loading = false;
                        toast.error("Error occured. Please try again.", 5);
                    }
                );
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    render() {
        var title = <div>Login ({env["OWNER"]})</div>;

        return (
            <Section className="py-0">
                <Row className="min-vh-100 py-6 justify-content-center">
                    <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
                        <div className="d-flex justify-content-center">
                            <img src="logo-v-148x142.png" style={{ backgroundColor: 'black', height: '80px', marginBottom: '10px' }} className="resize d-flex justify-content-center" alt="vizybility" />
                        </div>
                        <LoadingOverlay
                            active={this.props.applicationStore.loading}
                            spinner>
                            <Card>
                                <CardBody className="fs--1 font-weight-normal p-5">
                                    <Fragment>
                                        <Row className="text-left justify-content-between" style={{ marginBottom: '10px' }}>
                                            <Col xs="auto">
                                                <h5>{title}</h5>
                                            </Col>
                                            {/* <Col xs="auto">
                                                <p className="fs--1 text-600">
                                                or <Link to="/authentication/basic/register">create an account</Link>
                                                </p>
                                            </Col> */}
                                        </Row>
                                        <Form onSubmit={this.handleSubmit}>
                                            <FormGroup>
                                                <Input
                                                    placeholder='User Name'
                                                    value={this.state._username}
                                                    onChange={({ target }) => this.setState({ _username: target.value })}
                                                    type="text"
                                                />
                                                {this.validator.message('user name', this.state._username, 'required|email', { className: 'text-danger' })}
                                            </FormGroup>
                                            <FormGroup>
                                                <Input
                                                    placeholder='Password'
                                                    value={this.state._password}
                                                    onChange={({ target }) => this.setState({ _password: target.value })}
                                                    type="password"
                                                />
                                                {this.validator.message('password', this.state._password, 'required', { className: 'text-danger' })}
                                            </FormGroup>


                                            <FormGroup>
                                                <Label for="ApplicationId">
                                                    Select Applicaiton:
                                                </Label>
                                                <Input
                                                    type="select"
                                                    name="select"
                                                    id="ApplicationId"
                                                    value={this.state.ApplicationId}
                                                    onChange={({ target }) => this.setState({ ApplicationId: target.value })}
                                                >
                                                    <option key="0" value=""></option>
                                                    <option key="DMSDb_AlphaOmega" value="30203E77-726D-4788-9788-B52F6CEEA99A">AlphaOmega</option>
                                                    <option key="DMS_Dev" value="7E84E687-8B74-465F-87F4-835553B75E17">Dev</option>
                                                    <option key="DMSDb_DEV_QBONLINE" value="D53F6593-2025-4DE3-BB6B-FD6DDE8FC774">Dev QB Online</option>
                                                    <option key="DMSDb_DEV_QBDESKTOP" value="42F1B2CA-3B49-46BF-8FB9-93AE589B20A7">Dev QB Desktop</option>
                                                    <option key="DMSDb_DOUBLEEDGE" value="ED28DCAE-2A86-476F-8610-6C5B40D24939">DoubleEdge</option>
                                                    <option key="DMSDb_EQUIPMENTWORLD" value="5013bbd1-4525-45a1-a653-f104b0040aee">Equipment World</option>  
                                                    <option key="DMSDb_EVCON" value="A129D2D0-A4A0-4D8A-9924-4C2DBF97F8E9">Evcon</option>                                    
                                                    <option key="DMSDb_GCEHIRE" value="8B1FD21C-7B14-4B91-ACFF-B5251FC619D3">GCE Hire</option>
                                                    <option key="DMSDb_GLOBALTRACTOR" value="05E174E0-6B29-4EEE-A152-6F8EA13AF0AA">Global Tractor</option>
                                                    <option key="DMSDb_KORPAN" value="796b7bd0-e3ba-45e4-871d-03fc64cd2d00">Korpan</option>
                                                    <option key="DMS_Performance" value="5364B480-6CEF-456D-80B0-CE91AA97BE76">Performance</option>
                                                    <option key="DMS_PerformanceSandbox" value="72da5f89-308c-4131-aea4-15f5917729e6">Performance Sandbox</option>
                                                    <option key="DMSDb_se" value="EF99EE8B-ACD0-47FD-B5E2-5C04BB794C07">SE</option>
                                                    <option key="DMSDb_WWE" value="344268cc-9b4e-4c32-b987-e7c4b0c32b50">Simonson Equipment</option>
                                                    <option key="DMS_Startractor" value="1cb8d338-5432-4229-b8dc-d0d59d95750a">Startractor</option>
                                                    <option key="DMS_StartractorSandbox" value="c710c4b3-1051-4b85-884e-4da28adf03ce">Startractor Sandbox</option>
                                                    <option key="DMSDb_THOMSON" value="d3540e65-f664-4851-a5b8-a1000da6d3c2">Thompson Tractor</option>
                                                    <option key="DMS_Trophy" value="34cea9a6-3764-4fda-8d4e-e4958b93c9fd">Trophy Tractor</option>
                                                </Input>
                                                {this.validator.message('Applicaton', this.state.ApplicationId, 'required', { className: 'text-danger' })}

                                            </FormGroup>

                                            <Row className="justify-content-between align-items-center">
                                                <Col xs="auto">
                                                    <CustomInput
                                                        id="customCheckRemember"
                                                        label="Remember me"
                                                        checked={this.state._remember}
                                                        onChange={({ target }) => this.setState({ _remember: target.value })}
                                                        type="checkbox"
                                                    />
                                                </Col>
                                                {/* <Col xs="auto">
                                                <Link className="fs--1" to={`/authentication/${layout}/forget-password`}>
                                                    Forget Password?
                                                </Link>
                                                </Col> */}
                                            </Row>
                                            <FormGroup>
                                                <Button color="primary" block className="mt-3">
                                                    {/* <Spinner animation="border" variant="primary" style={{display: loading ? '' : 'none' }} /> */}
                                                    Submit
                                                </Button>
                                            </FormGroup>
                                            {/* <Divider className="mt-4">or log in with</Divider> */}
                                            {/* <SocialAuthButtons /> */}
                                            {/* <LoginWithMS ApplicationId={this.state.ApplicationId} /> */}
                                        </Form>
                                    </Fragment>
                                </CardBody>
                            </Card>
                        </ LoadingOverlay>
                    </Col>
                </Row>
            </Section>
        );
    }
}

// export default inject('applicationStore')(withRouter(Login));
export default Login;