import { makeObservable, observable, action, autorun } from 'mobx';
import { now } from 'mobx-utils'
import LocalStorageStore from '../apiStores/application/localStorageStore';
import moment from 'moment';


class SessionTracker {
    @observable isSoonToExpire = false;
    @observable isExpired = false;

    constructor(dataSource = []) {
        makeObservable(this);

        autorun(() => {
            this.checkExpired();
            this.checkSoonToExpire();

            // eslint-disable-next-line no-unused-expressions
            this.secondsTillSessionExpiry;

        });
    }

    get exp() {
        return LocalStorageStore.getToken().exp * 1000;
    }


    get duration() {
        var a = moment(now());
        var b = moment(this.exp);
        var m = b.diff(a);

        return moment.duration(m);
    }

    @action checkExpired() {
        if (Math.floor(this.secondsTillSessionExpiry) === 0) {
            this.isExpired = true;
        } else {
            this.isExpired = false;
        }
    }

    @action checkSoonToExpire() {
        if (Math.floor(this.secondsTillSessionExpiry) === 5 * 60) {
            this.isSoonToExpire = true;
        } else {
            this.isSoonToExpire = false;
        }
    }

    get secondsTillSessionExpiry() {
        return this.duration.asSeconds();
    }

    get expireTime() {
        return Math.floor(this.duration.hours()) + 'h ' + this.duration.minutes() + 'm ' + this.duration.seconds() + 's';
    }
}

const sessionTracker = new SessionTracker();
export default sessionTracker