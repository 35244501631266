import React, { Component } from 'react'
import { observer, inject } from "mobx-react";
import { Card, CardBody, Button, Spinner } from 'reactstrap';
import logo from './logo-v.png';

@inject("applicationStore")
@observer
class XeroContactCard extends Component {
    constructor(props) {
        super();
        this.state = {};
    }

    handleClick = () => {
        this.props.applicationStore.SyncXeroContact();
    }

    render() {
        return (
            <Card className="card-span h-00 mt-6">
                <div className="card-span-img">
                    <img style={{ backgroundColor: "black", borderRadius: "5px" }} className="fs-4" height="50px" src={logo} />
                </div>
                <CardBody className="pt-6 pb-4">
                    <Button color={"primary"} onClick={this.handleClick} outline><h5>Sync Contact</h5></Button>
                </CardBody>
            </Card>
        );

    }
}

export default XeroContactCard;
