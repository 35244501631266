// Please do not remove below CSS includes
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-vertical-timeline-component/style.min.css';

import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Login from './views/login/Login'
import LandingPage from './views/landing/LandingPage';
import { Restricted } from './customeComponents/restricted';
import { Session } from './customeComponents/session';

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div>
        <Session />
        <Switch>
          <Route exact path="/" breadcrumbName="Home" render={() => <Redirect to="/login" component={withRouter(Login)} />} />
          <Route path="/login" component={withRouter(Login)} />
          <Route path="/welcome" component={withRouter(Restricted(LandingPage))} />
        </Switch>
        <ToastContainer style={{ marginTop: '50px' }} />
      </div>
    </Router>
  );
};

export default App;
