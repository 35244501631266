import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import Header from './LandingPageheader/Header';
import ModuleCard from './ModuleCard';
import Section from '../../components/common/Section';
import applicationStore from '../../apiStores/application/applicationStore';
import XeroCard from './XeroCard';
import LoadingOverlay from 'react-loading-overlay';
import XeroInvoiceCard from './XeroInvoiceCard';
import XeroContactCard from './XeroContactCard';

@inject('applicationStore')
@observer
class LandingPage extends Component {
  constructor() {
    super();
    this.state = {};
    setTimeout(async () => {
      await applicationStore.GetCurrentModule();
      if (this.props.applicationStore?.currentModule?.includes('QuickBook')) {
        applicationStore.GetQBAccessToken();
      }
      if (this.props.applicationStore?.currentModule?.includes('Xero')) {
        applicationStore.GetXeroToken();
      }
    }, 100);
  }

  render() {
    console.log(this.props.applicationStore.currentModule);
    return (
      <div style={{ height: '100vh' }}>
        <div style={{ height: '80vh' }}>
          <LoadingOverlay active={this.props.applicationStore.loading} spinner>
            <Header />
            <Section className="text-center pt-4">
              <Row className="flex-center">
                {this.props.applicationStore?.currentModule?.includes('QuickBook') && (
                  <Col xs={5} sm={5} md={5} lg={5}>
                    <ModuleCard module={'CONNECT'} />
                  </Col>
                )}
                {this.props.applicationStore?.currentModule?.includes('Xero') && (
                  <Col xs={5} sm={5} md={5} lg={5}>
                    <XeroCard module={'CONNECT'} />
                  </Col>
                )}
              </Row>
              <Row className="flex-center">
                {this.props.applicationStore?.xeroAccessToken && (
                  <Col xs={5} sm={5} md={5} lg={5}>
                    <XeroContactCard />
                  </Col>
                )}
                {this.props.applicationStore?.xeroAccessToken && (
                  <Col xs={5} sm={5} md={5} lg={5}>
                    <XeroInvoiceCard />
                  </Col>
                )}
              </Row>
            </Section>
          </LoadingOverlay>
        </div>

        <div style={{ height: '20vh' }}>
          <div className="fotter">© Strategic Evolutions</div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
