import React, { useState, useContext } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Avatar from '../../../components/common/Avatar';
import AppContext from '../../../context/Context';
import Flex from '../../../components/common/Flex';
import * as msal from 'msal';
import { PublicClientApplication } from '@azure/msal-browser';
import env from '../../../configs/config';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { observer, inject } from 'mobx-react';
import counterpart from 'counterpart';
let UK = require('../../../assets/img/languageLogos/UK.png');
let France = require('../../../assets/img/languageLogos/France.png');

const msalConfig = {
  auth: {
    clientId: env['clientId'],
    authority: 'https://login.microsoftonline.com/common',
    knownAuthorities: [],
    cloudDiscoveryMetadata: '',
    redirectUri: env['redirectUri'],
    // postLogoutRedirectUri: "enter_postlogout_uri_here",
    navigateToLoginRequestUrl: true,
    clientCapabilities: ['CP1'],
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
          default:
        }
      },
    },
  },
};
const myMSALObj = new PublicClientApplication(msalConfig);

const ProfileDropdown = inject('applicationStore')(
  observer((props) => {
    const history = useHistory();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [LanguageModalView, setLanguageModalView] = useState(false);
    const [languages, setlanguages] = useState([
      { code: 'en-US', flag: require('../../../assets/img/languageLogos/UK.png') },
      { code: 'fr-FR', flag: require('../../../assets/img/languageLogos/France.png') },
      { code: 'corporate-en-GB', flag: require('../../../assets/img/languageLogos/UK.png') },
    ]);
    const [lang, setlang] = useState({ code: 'en-US', flag: require('../../../assets/img/languageLogos/UK.png') });
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const { isDark, setIsDark } = useContext(AppContext);

    const handleMenuClickLogout = () => {
      // props.applicationStore.logout();
      if (localStorage.getItem('ExternalLogin') === 'true') {
        localStorage.removeItem('ExternalLogin');
        const logoutRequest = {
          account: myMSALObj.getAccountByUsername(localStorage.getItem('userName')),
        };
        myMSALObj.logout(logoutRequest);
        props.applicationStore.logout();
      } else {
        setTimeout(() => {
          props.applicationStore.logout();
        }, 2000);
      }

      toast.info(
        <span>
          <i className="bi bi-box-arrow-left"></i>&nbsp;Signing out ...
        </span>
      );

      setTimeout(function () {
        history.push({ pathname: '/login' });
        toast.success('Signed out successfully.');
      }, 2000);
    };

    const refreshToken = () => {
      props.applicationStore.ClearTokens();
    };

    const languageView = (resp) => {
      if (resp.code === 'en-US') {
        return (
          <div>
            <img src={UK} />
            &nbsp;&nbsp; English (United State)
          </div>
        );
      } else if (resp.code === 'fr-FR') {
        return (
          <div>
            <img src={France} />
            &nbsp;&nbsp; French (France)
          </div>
        );
      }
    };

    const handleLanguage = (resp) => {
      setlang(resp);
      counterpart.setLocale(resp.code);
      setLanguageModalView(false);
    };

    return (
      <div>
        <Dropdown
          nav
          inNavbar
          isOpen={dropdownOpen}
          toggle={toggle}
          // onMouseOver={() => {
          //     let windowWidth = window.innerWidth;
          //     windowWidth > 992 && setDropdownOpen(true);
          // }}
          // onMouseLeave={() => {
          //     let windowWidth = window.innerWidth;
          //     windowWidth > 992 && setDropdownOpen(false);
          // }}
        >
          <DropdownToggle nav className="pr-0 h6">
            <Flex>
              <div style={{ marginTop: '-5px' }}>
                <Avatar size="l" name={props.applicationStore.getFullname()} />
                <div className="profile-name-div">&nbsp; {props.applicationStore.getFullname()}</div>
              </div>
            </Flex>
          </DropdownToggle>

          <DropdownMenu right className="dropdown-menu-card" style={{ marginRight: '50px' }}>
            <div className="bg-white rounded-soft py-2">
              <DropdownItem onClick={refreshToken}>Clear Refresh Token</DropdownItem>
              <DropdownItem onClick={handleMenuClickLogout}>Sign out</DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>

        <Modal isOpen={LanguageModalView}>
          <ModalHeader>Language Selection</ModalHeader>
          <ModalBody>
            {languages.map((resp) => (
              <div key={resp} onClick={() => handleLanguage(resp)} style={{ margin: 10, cursor: 'pointer' }}>
                {languageView(resp)}
              </div>
            ))}
          </ModalBody>
        </Modal>
      </div>
    );
  })
);

ProfileDropdown.defaultProps = {
  className: 'test',
};

export default ProfileDropdown;
