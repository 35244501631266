export default {
    "Dashboard": "Dashboard",
    "Login": "Login",
    "Available Assets": "Available Assets",
    "Leads To Action": "Leads To Action",
    "Assets Without Target Date": "Assets Without Target Date",
    "Open Activities": "Open Activities",
    "Companies need followup": "Companies need followup",
    "Contact need followup": "Contact need followup",
    "Forecast Replacement Sales": "Forecast Replacement Sales",
    "Sales Opportunity Forecast": "Sales Opportunity Forecast",
    "Open Leads": "Open Leads",
    "Missed Leads": "Missed Leads",
    "Add New": "Add New",
    "Recent Activities (Last 60 days)": "Recent Activities (Last 60 days)",
    "Lead Description": "Lead Description",
    "Company": "Company",
    "Model": "Model",
    "Status": "Status",
    "Date": "Date",
    "Subject": "Subject",
    "Activity Date": "Activity Date",
    "Companies": "Companies",
    "Contacts": "Contacts",
    "Locations": "Locations",
    "Assets": "Assets",
    "Activities": "Activities",
    "Leads": "Leads",
    "Admin": "Admin",
    "Profile": "Profile",
    "Reports": "Reports",
    "Advertisements": "Advertisements",
    "Inventory": "Inventory",
    "Marketing": "Marketing",
    "Calendar": "Calendar",
    "Add/Edit Activity": "Add/Edit Activity",
    "Activity Id": "Activity Id",
    "Duration": "Duration",
    "Description": "Description",
    "Location": "Location",
    "Activity Type": "Activity Type",
    "Activity Status": "Activity Status",
    "Save": "Save",
    "Add/Edit Lead": "Add/Edit Lead",
    "Main": "Main",
    "Short Desc": "Short Desc",
    "Long Desc": "Long Desc",
    "Lead Value": "Lead Value",
    "Lead Status": "Lead Status",
    "Reason": "Reason",
    "Likelihood": "Likelihood",
    "Note": "Note",
    "Lead Type": "Lead Type",
    "Target Decision Date": "Target Decision Date",
    "Followup Date": "Followup Date",
    "Contact": "Contact",
    "Responsibility": "Responsibility",
    "All Columns": "All Columns",
    "Address": "Address",
    "Type": "Type",
    "Ranking": "Ranking",
    "Submit": "Submit",
    "Find": "Find",
    "Add Company": "Add Company",
    "Merge Companies": "Merge Companies",
    "Last Activity Date": "Last Activity Date",
    "Company #": "Company #",
    "Action": "Action",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Email": "Email",
    "LinkedIn": "LinkedIn",
    "Mobile": "Mobile",
    "Contact Name": "Contact Name",
    "Contact Email": "Contact Email",
    "Contact Phone": "Contact Phone",
    "Featured": "Featured",
    "Yes": "Yes",
    "No": "No",
    "Title": "Title",
    "Contact Type": "Contact Type",
    "Office Phone": "Office Phone",
    "Home Phone": "Home Phone",
    "Edit": "Edit",
    "Move": "Move",
    "Disconnect From Company": "Disconnect From Company",
    "DisconnectWarn": "DisconnectWarn",
    "Press": "Press",
    "OK": "OK",
    "to continue": "to continue",
    "Disconnect Contact?": "Disconnect Contact?",
    "Show Active": "Show Active",
    "Company Name": "Company Name",
    "Address1": "Address1",
    "Postal Code": "Postal Code",
    "Country": "Country",
    "Province": "Province",
    "Phone": "Phone",
    "Fax": "Fax",
    "Types": "Types",
    "City": "City",
    "Time": "Time",
    "Reminder": "Reminder",
    "Change User": "Change User",
    "Change Date": "Change Date",
    "DeleteRecordWarn": "You are about to delete the record.",
    "DeleteRecord": "Delete Record",
    "Lead Id": "Lead Id",
    "Quote Status": "Quote Status",
    "Followup": "Followup",
    "Quote Value": "Quote Value",
    "Quote #": "Quote #",
    "Advertiser": "Advertiser",
    "Advertised Price": "Advertised Price",
    "Advertised Currency": "Advertised Currency",
    "Start Date": "Start Date",
    "End Date": "End Date",
    "Main Picture": "Main Picture",
    "Asset Type": "Asset Type",
    "Make": "Make",
    "Serial Number": "Serial Number",
    "Currency": "Currency",
    "Expired": "Expired",
    "Enabled": "Enabled",
    "Sort Order": "Sort Order",
    "Refresh": "Refresh",
    "Refreshing": "Refreshing ...",
    "Year": "Year",
    "SMU": "SMU",
    "Asset Desc": "Asset Desc",
    "Target Sell Date": "Target Sell Date",
    "Categorization Breadcrumb": "Categorization Breadcrumb",
    "Add Asset": "Add Asset",
    "PDF Summary": "PDF Summary",
    "Mailchimp Settings": "Mailchimp Settings",
    "Synchronization Log": "Synchronization Log",
    "Connect": "Connect",
    "Audiences": "Audiences",
    "Save Settings": "Save Settings",
    "Sync with": "Sync with",
    "Created": "Created",
    "Delete": "Delete",
    "Add Location": "Add Location",
    "SchemaId": "SchemaId",
    "Schema": "Schema",
    "User Name": "User Name",
    "Edit Schema": "Edit Schema",
    "Add/Edit Schema": "Add/Edit Schema",
    "Copy To": "Copy To",
    "Remember me": "Remember me",
    "Unit Number": "Unit Number",
    "Asset Status": "Asset Status",
    "Serial": "Serial",
    "Serial #": "Serial #",
    "Add Component": "Add Component",
    "Asset Type Categorization": "Asset Type Categorization",
    "Edit categorization": "Edit categorization",
    "Sold Date": "Sold Date",
    "Birthday": "Birthday",
    "Purchase Date": "Purchase Date",
    "Replacement Value": "Replacement Value",
    "SMU Date": "SMU Date",
    "Category": "Category",
    "Last SMU Date": "Last SMU Date",
    "Date Range": "Date Range",
    "Transaction Type": "Transaction Type",
    "Vendor": "Vendor",
    "Billable": "Billable",
    "Amount": "Amount",
    "Vendor Inv #": "Vendor Inv #",
    "Add Expense": "Add Expense",
    "Re-calculate forecast": "Re-calculate forecast",
    "Margin": "Margin",
    "Purchase Price": "Purchase Price",
    "Total Expenses": "Total Expenses",
    "Total Cost": "Total Cost",
    "Total Income": "Total Income",
    "Forecast Profit": "Forecast Profit",
    "Asking Price": "Asking Price",
    "Picture Id": "Picture Id",
    "Select File": "Select File",
    "Picture": "Picture",
    "Picture Name": "Picture Name",
    "Email Customer": "Email Customer",
    "Email": "Email",
    "Delete Selected Pic": "Delete Selected Pic",
    "Add Picture": "Add Picture",
    "Bulk Upload": "Bulk Upload",
    "Choose Contact to send": "Choose Contact to send",
    "Upload": "Upload",
    "To": "To",
    "Attachments": "Attachments",
    "Select Template": "Select Template",
    "None": "None",
    "Username": "Username",
    "Password": "Password",
    "Priority": "Priority",
    "High": "High",
    "Normal": "Normal",
    "Send Email": "Send Email",
    "Body": "Body",
    "Exclusions": "Exclusions",
    "Inclusions": "Inclusions",
    "Expiration Type": "Expiration Type",
    "Warranty Type": "Warranty Type",
    "Add Warranty": "Add Warranty",
    "ID": "ID",
    "Brands": "Brands",
    "Quick add a": "Quick add a ",
    "Brand": "Brand",
    "to master list": " to master list",
    "Add Comment": "Add Comment",
    "Parent Company": "Parent Company",
    "Address 1": "Address 1",
    "Address 2": "Address 2",
    "State/Prov": "State/Prov",
    "Zip/Postal Cd": "Zip/Postal Cd",
    "Customer #": "Customer #",
    "Pref Lang": "Pref Lang",
    "Comp Type": "Comp Type",
    "Industry": "Industry",
    "Website": "Website",
    "Contact Freq": "Contact Freq",
    "SalesRep": "SalesRep",
    "Ownership": "Ownership",
    "Analysis": "Analysis",
    "Add Contact": "Add Contact",
    "Add New Contact": "Add New Contact",
    "Choose Company to move to": "Choose Company to move to",
    "Unit ID": "Unit ID",
    "Target Date": "Target Date",
    "Price": "Price",
    "View Document": "View Document",
    "Add Lead": "Add Lead",
    "Quote Number": "Quote Number",
    "Location Name": "Location Name",
    "State/Province": "State/Province",
    "Latitude": "Latitude",
    "Longitude": "Longitude",
    "PostalCode": "PostalCode",
    "Loc Types Count": "Loc Types Count",
    "Loc Contacts Count": "Loc Contacts Count",
    "Change Password": "Change Password",
    "Password guideline": "Password guideline",
    "Salutation": "Salutation",
    "Reports To": "Reports To",
    "Mobile Phone": "Mobile Phone",
    "Sales Opportunity By Industry": "Sales Opportunity By Industry",
    "Sign out": "Sign out",
    "Phone Number": "Phone Number",
    "View": "View",
    "Alternate Price": "Alternate Price",
    "Alternate Currency": "Alternate Currency",
    "Sales Manager": "Sales Manager",
    "Settings": "Settings",
    "Brands Represented": "Brands Represented",
    "Asset Info": "Asset Info",
    "Components": "Components",
    "Warranties": "Warranties",
    "Expenses": "Expenses",
    "Pictures": "Pictures",
    "Documents": "Documents",
    "Remove": "Remove",
    "Options": "Options",
    "Trans. Date": "Trans. Date",
    "Vendor Inv. #": "Vendor Inv. #",
    "Add/Edit Advertisement": "Add/Edit Advertisement",
    "Add/Edit Expense": "Add/Edit Expense",
    "Add/Edit Picture": "Add/Edit Picture",
    "Add/Edit Warranty": "Add/Edit Warranty",
    "Add/Edit Document": "Add/Edit Document",
    "Add/Edit Location": "Add/Edit Location",
    "Save and Close": "Save and Close",
    "Save and Add New": "Save and Add New",
    "Select Documents": "Select Documents",
    "Print": "Print",
    "Add Advertisement": "Add Advertisement",
    "Find Existing Customer for New Lead": "Find Existing Customer for New Lead",
    "Move Asset": "Move Asset",
    "Select and Add Lead": "Select and Add Lead",
    "Choose Company to Add Lead to ": "Choose Company to Add Lead to ",
    "Provide a Reason": "Provide a Reason",
    "No Company Selected": "No Company Selected",
    "Comments": "Comments",
    "Cancel": "Cancel",
    "t_found_bro": "t_found_bro",
    "Geo Lookup": "Geo Lookup",
    "Street": "Street",
    "Postal": "Postal",
    "State": "State",
    "Pick Location": "Pick Location",
    "Last edited by": "Last edited by",
    "Location Count": "Location Count",
    "Contacts Count": "Contacts Count",
    "Leads Count": "Leads Count",
    "Activities Count": "Activities Count",
    "Sales Rep": "Sales Rep",
    "Add Activity": "Add Activity",
    "Priority Leads": "Priority Leads",
    "Priority Assets": "Priority Assets",
    "Leads to followup": "Leads to followup",
    "Lead Type Categorization": "Lead Type Categorization",
    "Categorize Lead": "Categorize Lead",
    "Hide Matching Assets": "Hide Matching Assets",
    "Show Matching Assets": "Show Matching Assets",
    "Table Name": "Table Name",
    "Main Tab Location": "Main Tab Location",
    "There are no": "There are no",
    "activities": "activities",
    "recorded": "recorded",
    "Address2": "Address2",
    "Location Types": "Location Types",
    "Show Only Inventory": "Show Only Inventory",
    "Setting Id": "Setting Id",
    "Setting Name": "Setting Name",
    "Setting Value": "Setting Value",
    "Available To Admin": "Available To Admin",
    "User Added Field": "User Added Field",
    "Edit Setting": "Edit Setting",
    "Add Setting": "Add Setting",

    "Add New Document": "Add New Document",
    "File Name": "File Name",
    "Document Description": "Document Description",
    "Document Type": "Document Type",
    "Version": "Version",
    "Upload Date": "Upload Date",
    "All Version": "All Version",
    "Loading": "Loading...",
    "Select Document": "Select Document",
    "Find Existing Customer for New Asset": "Find Existing Customer for New Asset",
    "Select and Add Asset": "Select and Add Asset",
    "Choose Company to Add Lead to": "Choose Company to Add Lead to",
    "Choose Company to Add Asset to": "Choose Company to Add Asset to",
    "Phone Call": "Phone Call",
    "Site Visit": "Site Visit",
    "Activity Date & Time": "Activity Date & Time",
    "Created by": "Created by",
    "Changed by": "Changed by",
    "Asset Details": "Asset Details",
    "items": "items",
    "Price Lookup": "Price Lookup",
    "Categorize Asset": "Categorize Asset",
    "Create Replacement Lead": "Create Replacement Lead",
    "Categorize this asset": "Categorize this asset",
    "Save and Reload": "Save and Reload",
    "Component Info": "Component Info",
    "Updated Date": "Updated Date",
    "Document association": "Document association",
    "Associated with": "Associated with",
    "Hours": "Hours",
    "Asset Categorization Text1": "Use this tool to create a detailed categorization of the asset. This standardized breakdown creates the ability to compare equivalent machines from different manufacturers.",
    "Asset Categorization Text2": "Each level is a sub level of the previous one, stop when ever you feel grouping is accurate enough of the next level is empty.",
    "Tier1": "Tier 1",
    "Tier2": "Tier 2",
    "Tier3": "Tier 3",
    "Tier4": "Tier 4",
    "Used Equipment Guide": "Used Equipment Guide",
    "Market Price Calculator powered by": "Market Price Calculator powered by",

    "MinYear": "Min. Year",
    "MaxYear": "Max. Year",
    "MinSMU": "Min. SMU",
    "MaxSMU": "Max. SMU",
    "Delete Picture": "Delete Picture",
    "Download Picture": "Download Picture",
    "Upcoming Asset Replacements (6 Month)": "Upcoming Asset Replacements (6 Month)",
    "Add New Component": "Add New Component",
    "Upload Multiple": "Upload Multiple",
    "Owner": "Owner",
    "Select a Category": "Select a Category",
    "Log Id": "Log Id",
    "Api Key": "Api Key",
    "ListId": "ListId",
    "Successful": "Successful",
    "User Name": "User Name",
    "Create Date": "Create Date",
    "Synchronize Contacts": "Synchronize Contacts",
    "Transaction Date": "Transaction Date",
    "Merge Companies": "Merge Companies",
    "Merge Into": "Merge Into",
    "Merge From": "Merge From",
    "No Data": "No Data",
    "API Key": "API Key",
    "Asset Categorization": "Asset Categorization",
    "Add": "Add",

    "Quotes": "Quotes",
    "Quote #": "Quote #",
    "Revision #": "Revision #",
    "Revision #": "Revision #",
    "Quote Date": "Quote Date",
    "Add Quote": "Add Quote",
    "Lead Subject": "Lead Subject",
    "Equipment Desc": "Equipment Desc.",
    "Quote Type": "Quote Type",
    "Expiration Date": "Expiration Date",
    "Flat Price": "Flat Price",
    "Added Tax": "Added Tax",
    "INCO Term": "INCO Term",
    "Template": "Template",
    "Package/Options": "Package/Options",
    "Save Current Revision": "Save Current Revision",
    "Save As New Revision": "Save As New Revision",
    "Quote Header Text": "Quote Header Text",

    "Segment #": "Segment #",
    "Segment Type": "Segment Type",
    "Add New Segment": "Add New Segment",
    "Segments": "Segments",
    "Machine Total": "Machine Total",
    "Other Total": "Other Total",
    "Taxable": "Taxable",
    "Condition": "Condition",
    "Features": "Features",
    "Pictures ": "Pictures ",
    "Short Description": "Short Description",

    "Asset Details": "Asset Details",
    "Segment Type": "Segment Type",
    "Current SMU": "Current SMU",
    "Show Condition": "Show Condition",
    "Show Features": "Show Features",

    "Item Type": "Item Type",
    "Quantity": "Quantity",
    "Cost": "Cost",
    "Total": "Total",
    "ItemNumber": "Item #",
    "Sell Price": "Sell Price",
    "Add New Item": "Add New Item",
    "Item Number": "Item #",
    "Source": "Source",
    "Items": "Items",
    "Move to Segment": "Move to Segment",
    "Segment Desc": "Segment Desc",

    "Save and Close": "Save and Close",
    "Feature": "Feature",
    "Show on Quote": "Show on Quote",
    "Revision": "Revision",
    "Add New Feature": "Add New Feature",
    "View as HTML": "View as HTML",

    "Leads without Company": "Leads without Company",

    "Won Leads": "Won Leads",
    "Leads Forecast": "Leads Forecast",
    "Delivery Fees": "Delivery Fees",
    "Delivery Cost NOT included": "Delivery Cost NOT included",
    "State/Province": "State/Province",
    "Select Pictures": "Select Pictures",
    "Advertise": "Advertise",
    "Organization": "Organization",
    "Frequency": "Frequency",
    "Frequency Unit": "Frequency Unit",

    "County": "County",

    "Light Mode": "Light Mode",
    "Dark Mode": "Dark Mode",
    "Sales Analysis": "Sales Analysis",
    "Brand Represented": "Brand Represented",

    "Add/Edit Folder": "Add/Edit Folder",
    "Select Folder": "Select Folder:",
    "Folder": "Folder",
    "Folder Id": "Folder Id",
    "Folder Name": "Folder Name",
    "Sequence": "Sequence",
    "Active": "Active",
    "Move to Folder": "Move to Folder",
    "AltText": "AltText",
    "Caption": "Caption",

    "Inspections": "Inspections",
    "Work Orders": "Work Orders",
    "Service Reports": "Service Reports",
    "Backlog": "Backlog",
    "Backlog Item": "Backlog Item",
    "Backlog Description": "Backlog Description",
    "Target Inpsection Date": "Target Inpsection Date",
    "Inspection Type": "Inspection Type",
    "Technician": "Technician",
    "Approval Required": "Approval Required",

    "Hold Machine": "Hold Machine",
    "Release Machine": "Release Machine",
    "Approve": "Approve",
    "Close": "Close",

    "Area": "Area",
    "Task": "Task",
    "Value": "Value",
    "Comment": "Comment",
    "Approval": "Approval",
    "Add to Backlog": "Add to Backlog",
    "Notes": "Notes",

    "Inspection Images": "Inspection Images",
    "Task Images": "Task Images",
    "TaskID": "TaskID",
    "In Supervisor Review": "In Supervisor Review",
    "Open Inspection": "Open Inspection",
    "Recent Inspections": "Recent Inspections",
    "Add Video": "Add Video",
    "Name": "Name",

    "Item ID": "Item ID",
    "Logged Date": "Logged Date",
    "Task Id": "Task Id",
    "Add/Edit Bcklog": "Add/Edit Backlog",

    "Add All Advertisers": "Add All Advertisers",
    "Select Picture": "Select Picture"
}