import React, { Component } from 'react'
import { observer, inject } from "mobx-react";
import { Card, CardBody, Button, Spinner } from 'reactstrap';
import logo from './logo-v.png';
import globalStore from '../../apiStores/application/globalStore';
import applicationStore from '../../apiStores/application/applicationStore';

@inject("applicationStore")
@observer
class XeroCard extends Component {
    constructor(props) {
        super();
        this.state = {};
    }

    handleClick = () => {
        this.props.applicationStore.connectXero();
    }
    handleCreateInvoiceClick = () => {
        this.props.applicationStore.CreateXeroInvoice();
    }

    render() {
        return (
            <Card className="card-span h-00 mt-6">
                <div className="card-span-img">
                    <img style={{ backgroundColor: "black", borderRadius: "5px" }} className="fs-4" height="50px" src={logo} />
                </div>
                <CardBody className="pt-6 pb-4">
                    {this.props.applicationStore.xeroAccessToken == null ?
                        <Spinner animation="border" variant="primary" />
                        :
                        !this.props.applicationStore.xeroAccessToken
                            ?
                            <div>
                                <h4 className="pt-4 pb-2">
                                    Click below to connect with Xero
                                </h4>
                                <Button color={"primary"} onClick={this.handleClick} outline><h5>{this.props.module}</h5></Button>
                            </div>
                            :
                            <div>
                                <h4 className="pt-4 pb-2">
                                    Congratulation! You are successfully Connected to Xero!
                                </h4>
                            </div>
                    }
                </CardBody>
            </Card>
        );

    }
}

export default XeroCard;
