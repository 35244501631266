export default {
	"Action": "Action",
	"Activities": "Activities",
	"activities": "activities",
	"Activities Count": "Activities Count",
	"Activity Date": "Activity Date",
	"Activity Date & Time": "Activity Date & Time",
	"Activity Id": "Activity Id",
	"Activity Status": "Activity Status",
	"Activity Type": "Activity Type",
	"Add": "Add",
	"Add Activity": "Add Activity",
	"Add Advertisement": "Add Advertisement",
	"Add Asset": "Add Asset",
	"Add Comment": "Add Comment",
	"Add Company": "Add Company",
	"Add Component": "Add Component",
	"Add Contact": "Add Contact",
	"Add Expense": "Add Expense",
	"Add Lead": "Add Lead",
	"Add Location": "Add Location",
	"Add New": "Add New",
	"Add New Component": "Add New Component",
	"Add New Contact": "Add New Contact",
	"Add New Document": "Add New Document",
	"Add Picture": "Add Picture",
	"Add Setting": "Add Setting",
	"Add Warranty": "Add Warranty",
	"Add/Edit Activity": "Add/Edit Activity",
	"Add/Edit Advertisement": "Add/Edit Advertisement",
	"Add/Edit Document": "Add/Edit Document",
	"Add/Edit Expense": "Add/Edit Expense",
	"Add/Edit Lead": "Add/Edit Lead",
	"Add/Edit Location": "Add/Edit Location",
	"Add/Edit Picture": "Add/Edit Picture",
	"Add/Edit Warranty": "Add/Edit Warranty",
	"Address": "Address",
	"Address 1": "Address 1",
	"Address 2": "Address 2",
	"Address1": "Address1",
	"Address2": "Address2",
	"Admin": "Admin",
	"Advertised Currency": "Advertised Currency",
	"Advertised Price": "Advertised Price",
	"Advertisements": "Advertisements",
	"Advertiser": "Advertiser",
	"All Columns": "All Columns",
	"All Version": "All Version",
	"Alternate Currency": "Alternate Currency",
	"Alternate Price": "Alternate Price",
	"Amount": "Amount",
	"Analysis": "Analysis",
	"Api Key": "Api Key",
	"API Key": "API Key",
	"Asking Price": "Asking Price",
	"Asset Categorization": "Asset Categorization",
	"Asset Categorization Text1": "Use this tool to create a detailed categorization of the asset. This standardized breakdown creates the ability to compare equivalent machines from different manufacturers.",
	"Asset Categorization Text2": "Each level is a sub level of the previous one",
	"Asset Desc": "Asset Desc",
	"Asset Details": "Asset Details",
	"Asset Info": "Asset Info",
	"Asset Status": "Asset Status",
	"Asset Type": "Asset Type",
	"Asset Type Categorization": "Asset Type Categorization",
	"Assets": "Assets",
	"Assets Without Target Date": "Assets Without Target Date",
	"Associated with": "Associated with",
	"Attachments": "Attachments",
	"Audiences": "Audiences",
	"Available Assets": "Available Assets",
	"Available To Admin": "Available To Admin",
	"Billable": "Billable",
	"Birthday": "Birthday",
	"Body": "Body",
	"Brand": "Brand",
	"Brands": "Brands",
	"Brands Represented": "Brands Represented",
	"Bulk Upload": "Bulk Upload",
	"Calendar": "Calendar",
	"Cancel": "Cancel",
	"Categorization Breadcrumb": "Categorization Breadcrumb",
	"Categorize Lead": "Categorize Lead",
	"Categorize this asset": "Categorize this asset",
	"Category": "Category",
	"Change Date": "Change Date",
	"Change Password": "Change Password",
	"Change User": "Change User",
	"Changed by": "Changed by",
	"Choose Company to Add Asset to": "Choose Company to Add Asset to",
	"Choose Company to Add Lead to": "Choose Company to Add Lead to",
	"Choose Company to move to": "Choose Company to move to",
	"Choose Contact to send": "Choose Contact to send",
	"City": "City",
	"Comments": "Comments",
	"Comp Type": "Comp Type",
	"Companies": "Companies",
	"Companies need followup": "Companies need followup",
	"Contact need followup": "Contact need followup",
	"Company": "Company",
	"Company #": "Company #",
	"Company Name": "Company Name",
	"Component Info": "Component Info",
	"Components": "Components",
	"Connect": "Connect",
	"Contact": "Contact",
	"Contact Email": "Contact Email",
	"Contact Freq": "Contact Freq",
	"Contact Name": "Contact Name",
	"Contact Phone": "Contact Phone",
	"Contact Type": "Contact Type",
	"Contacts": "Contacts",
	"Contacts Count": "Contacts Count",
	"Copy To": "Copy To",
	"Country": "Country",
	"Create Date": "Create Date",
	"Create Replacement Lead": "Create Replacement Lead",
	"Created": "Created",
	"Created by": "Created by",
	"Currency": "Currency",
	"Customer #": "Customer #",
	"Dashboard": "Dashboard",
	"Date": "Date",
	"Date Range": "Date Range",
	"Delete": "Delete",
	"Delete Picture": "Delete Picture",
	"Delete Selected Pic": "Delete Selected Pic",
	"DeleteRecord": "Delete Record",
	"DeleteRecordWarn": "You are about to delete the record.",
	"Description": "Description",
	"Disconnect Contact?": "Disconnect Contact?",
	"Disconnect From Company": "Disconnect From Company",
	"DisconnectWarn": "You are about to Disconnect this Contact",
	"Document association": "Document association",
	"Document Description": "Document Description",
	"Document Type": "Document Type",
	"Documents": "Documents",
	"Download Picture": "Download Picture",
	"Duration": "Duration",
	"Edit": "Edit",
	"Edit categorization": "Edit categorization",
	"Edit Schema": "Edit Schema",
	"Add/Edit Schema": "Add/Edit Schema",
	"Edit Setting": "Edit Setting",
	"Email": "Email",
	"Email Customer": "Email Customer",
	"Email": "Email",
	"Enabled": "Enabled",
	"End Date": "End Date",
	"Exclusions": "Exclusions",
	"Expenses": "Expenses",
	"Expiration Type": "Expiration Type",
	"Expired": "Expired",
	"Fax": "Fax",
	"Featured": "Featured",
	"File Name": "File Name",
	"Find": "Find",
	"Find Existing Customer for New Asset": "Find Existing Customer for New Asset",
	"Find Existing Customer for New Lead": "Find Existing Customer for New Lead",
	"First Name": "First Name",
	"Followup": "Followup",
	"Followup Date": "Followup Date",
	"Forecast Profit": "Forecast Profit",
	"Forecast Replacement Sales": "Forecast Replacement Sales",
	"Geo Lookup": "Geo Lookup",
	"Hide Matching Assets": "Hide Matching Assets",
	"High": "High",
	"Home Phone": "Home Phone",
	"Hours": "Hours",
	"ID": "ID",
	"Inclusions": "Inclusions",
	"Industry": "Industry",
	"Inventory": "Inventory",
	"items": "items",
	"Last Activity Date": "Last Activity Date",
	"Last edited by": "Last edited by",
	"Last Name": "Last Name",
	"Last SMU Date": "Last SMU Date",
	"Latitude": "Latitude",
	"Lead Description": "Lead Description",
	"Lead Id": "Lead Id",
	"Lead Status": "Lead Status",
	"Lead Type": "Lead Type",
	"Lead Type Categorization": "Lead Type Categorization",
	"Lead Value": "Lead Value",
	"Leads": "Leads",
	"Leads Count": "Leads Count",
	"Leads To Action": "Leads To Action",
	"Leads to followup": "Leads to followup",
	"Likelihood": "Likelihood",
	"LinkedIn": "LinkedIn",
	"ListId": "ListId",
	"Loading": "Loading...",
	"Loc Contacts Count": "Loc Contacts Count",
	"Loc Types Count": "Loc Types Count",
	"Location": "Location",
	"Location Count": "Location Count",
	"Location Name": "Location Name",
	"Location Types": "Location Types",
	"Locations": "Locations",
	"Log Id": "Log Id",
	"Login": "Login",
	"Long Desc": "Long Desc",
	"Longitude": "Longitude",
	"Mailchimp Settings": "Mailchimp Settings",
	"Main": "Main",
	"Main Picture": "Main Picture",
	"Main Tab Location": "Main Tab Location",
	"Make": "Make",
	"Margin": "Margin",
	"Market Price Calculator powered by": "Market Price Calculator powered by",
	"Marketing": "Marketing",
	"MaxSMU": "Max. SMU",
	"MaxYear": "Max. Year",
	"Merge Companies": "Merge Companies",
	"Merge From": "Merge From",
	"Merge Into": "Merge Into",
	"MinSMU": "Min. SMU",
	"MinYear": "Min. Year",
	"Mobile": "Mobile",
	"Mobile Phone": "Mobile Phone",
	"Model": "Model",
	"Move": "Move",
	"Move Asset": "Move Asset",
	"No": "No",
	"No Company Selected": "No Company Selected",
	"No Data": "No Data",
	"None": "None",
	"Normal": "Normal",
	"Note": "Note",
	"Office Phone": "Office Phone",
	"OK": "OK",
	"Open Activities": "Open Activities",
	"Open Leads": "Open Leads",
	"Missed Leads": "Missed Leads",
	"Options": "Options",
	"Owner": "Owner",
	"Ownership": "Ownership",
	"Parent Company": "Parent Company",
	"Password": "Password",
	"Password guideline": "Password guideline",
	"PDF Summary": "PDF Summary",
	"Phone": "Phone",
	"Phone Call": "Phone Call",
	"Phone Number": "Phone Number",
	"Pick Location": "Pick Location",
	"Picture": "Picture",
	"Picture Id": "Picture Id",
	"Picture Name": "Picture Name",
	"Pictures": "Pictures",
	"Postal": "Postal",
	"Postal Code": "Postal Code",
	"PostalCode": "Postal Code",
	"Pref Lang": "Pref Lang",
	"Press": "Press",
	"Price": "Price",
	"Price Lookup": "Price Lookup",
	"Print": "Print",
	"Priority": "Priority",
	"Priority Assets": "Priority Assets",
	"Priority Leads": "Priority Leads",
	"Profile": "Profile",
	"Provide a Reason": "Provide a Reason",
	"Province": "Province",
	"Purchase Date": "Purchase Date",
	"Purchase Price": "Purchase Price",
	"Quick add a": "Quick add a",
	"Quote #": "Quote #",
	"Quote Number": "Quote Number",
	"Quote Status": "Quote Status",
	"Quote Value": "Quote Value",
	"Ranking": "Ranking",
	"Reason": "Reason",
	"Re-calculate forecast": "Re-calculate forecast",
	"Recent Activities (Last 60 days)": "Recent Activities (Last 60 days)",
	"recorded": "recorded",
	"Refresh": "Refresh",
	"Refreshing": "Refreshing ...",
	"Remember me": "Remember me",
	"Reminder": "Reminder",
	"Remove": "Remove",
	"Replacement Value": "Replacement Value",
	"Reports": "Reports",
	"Reports To": "Reports To",
	"Responsibility": "Responsibility",
	"Sales Manager": "Sales Manager",
	"Sales Opportunity By Industry": "Sales Opportunity By Industry",
	"Sales Opportunity Forecast": "Sales Opportunity Forecast",
	"Sales Rep": "Sales Rep",
	"Sales Representative": "Sales Representative",
	"SalesRep": "SalesRep",
	"Salutation": "Salutation",
	"Save": "Save",
	"Save and Add New": "Save and Add New",
	"Save and Close": "Save and Close",
	"Save and Reload": "Save and Reload",
	"Save Settings": "Save Settings",
	"Schema": "Schema",
	"SchemaId": "SchemaId",
	"Select a Category": "Select a Category",
	"Select and Add Asset": "Select and Add Asset",
	"Select and Add Lead": "Select and Add Lead",
	"Select Document": "Select Document",
	"Select Documents": "Select Documents",
	"Select File": "Select File",
	"Select Template": "Select Template",
	"Send Email": "Send Email",
	"Serial": "Serial",
	"Serial #": "Serial #",
	"Serial Number": "Serial Number",
	"Setting Id": "Setting Id",
	"Setting Name": "Setting Name",
	"Setting Value": "Setting Value",
	"Settings": "Settings",
	"Short Desc": "Short Desc",
	"Show Active": "Show Active",
	"Show Matching Assets": "Show Matching Assets",
	"Show Only Inventory": "Show Only Inventory",
	"Sign out": "Sign out",
	"Site Visit": "Site Visit",
	"SMU": "SMU",
	"SMU Date": "SMU Date",
	"Sold Date": "Sold Date",
	"Sort Order": "Sort Order",
	"Start Date": "Start Date",
	"State": "State",
	"State/Prov": "State/Prov",
	"State/Province": "State/Province",
	"Status": "Status",
	"Street": "Street",
	"Subject": "Subject",
	"Submit": "Submit",
	"Successful": "Successful",
	"Sync with": "Sync with",
	"Synchronization Log": "Synchronization Log",
	"Synchronize Contacts": "Synchronize Contacts",
	"t_found_bro": "t_found_bro",
	"Table Name": "Table Name",
	"Target Date": "Target Date",
	"Target Decision Date": "Target Decision Date",
	"Target Sell Date": "Target Sell Date",
	"There are no": "There are no",
	"Tier1": "Tier 1",
	"Tier2": "Tier 2",
	"Tier3": "Tier 3",
	"Tier4": "Tier 4",
	"Time": "Time",
	"Title": "Title",
	"To": "To",
	"to continue": "to continue",
	"to master list": "to master list",
	"Total Cost": "Total Cost",
	"Total Expenses": "Total Expenses",
	"Total Income": "Total Income",
	"Trans. Date": "Trans. Date",
	"Transaction Date": "Transaction Date",
	"Transaction Type": "Transaction Type",
	"Type": "Type",
	"Types": "Types",
	"Unit ID": "Unit ID",
	"Unit Number": "Unit Number",
	"Upcoming Asset Replacements (6 Month)": "Upcoming Asset Replacements (6 Month)",
	"Updated Date": "Updated Date",
	"Upload": "Upload",
	"Upload Date": "Upload Date",
	"Upload Multiple": "Upload Multiple",
	"Used Equipment Guide": "Used Equipment Guide",
	"User Added Field": "User Added Field",
	"User Name": "User Name",
	"Username": "Username",
	"Vendor": "Vendor",
	"Vendor Inv #": "Vendor Inv #",
	"Vendor Inv. #": "Vendor Inv. #",
	"Version": "Version",
	"View": "View",
	"View Document": "View Document",
	"Warranties": "Warranties",
	"Warranty Type": "Warranty Type",
	"Website": "Website",
	"Year": "Year",
	"Yes": "Yes",
	"Zip/Postal Cd": "Zip/Postal Cd",

	"Quotes": "Quotes",
	"Quote #": "Quote #",
	"Revision #": "Revision #",
	"Revision #": "Revision #",
	"Quote Date": "Quote Date",
	"Add Quote": "Add Quote",
	"Lead Subject": "Lead Subject",
	"Equipment Desc": "Equipment Desc.",
	"Quote Type": "Quote Type",
	"Expiration Date": "Expiration Date",
	"Flat Price": "Flat Price",
	"Added Tax": "Added Tax",
	"INCO Term": "INCO Term",
	"Template": "Template",
	"Package/Options": "Package/Options",
	"Save Current Revision": "Save Current Revision",
	"Save As New Revision": "Save As New Revision",
	"Quote Header Text": "Quote Header Text",

	"Segment #": "Segment #",
	"Segment Type": "Segment Type",
	"Add New Segment": "Add New Segment",
	"Segments": "Segments",
	"Machine Total": "Machine Total",
	"Other Total": "Other Total",
	"Taxable": "Taxable",
	"Condition": "Condition",
	"Features": "Features",
	"Pictures ": "Pictures ",
	"Short Description": "Short Description",

	"Asset Details": "Asset Details",
	"Segment Type": "Segment Type",
	"Current SMU": "Current SMU",
	"Show Condition": "Show Condition",
	"Show Features": "Show Features",

	"Item Type": "Item Type",
	"Quantity": "Quantity",
	"Cost": "Cost",
	"Total": "Total",
	"ItemNumber": "Item #",
	"Sell Price": "Sell Price",
	"Add New Item": "Add New Item",
	"Item Number": "Item #",
	"Source": "Source",
	"Items": "Items",
	"Move to Segment": "Move to Segment",
	"Segment Desc": "Segment Desc",

	"Save and Close": "Save and Close",
	"Feature": "Feature",
	"Show on Quote": "Show on Quote",
	"Revision": "Revision",
	"Add New Feature": "Add New Feature",
	"View as HTML": "View as HTML",

	"Categorize Asset": "Categorize Asset",

	"Leads without Company": "Leads without Company",

	"Won Leads": "Won Leads",
	"Leads Forecast": "Leads Forecast",
	"Delivery Fees": "Delivery Fees",
	"Delivery Cost NOT included": "Delivery Cost NOT included",
	"State/Province": "State/Province",
	"Select Pictures": "Select Pictures",
	"Advertise": "Advertise",
	"Organization": "Organization",
	"Frequency": "Frequency",
	"Frequency Unit": "Frequency Unit",

	"County": "County",

	"Light Mode": "Light Mode",
	"Dark Mode": "Dark Mode",
	"Sales Analysis": "Sales Analysis",
	"Brand Represented": "Brand Represented",

	"Add/Edit Folder": "Add/Edit Folder",
	"Select Folder": "Select Folder:",
	"Folder": "Folder",
	"Folder Id": "Folder Id",
	"Folder Name": "Folder Name",
	"Sequence": "Sequence",
	"Active": "Active",
	"Move to Folder": "Move to Folder",
	"AltText": "AltText",
	"Caption": "Caption",

	"Inspections": "Inspections",
	"Work Orders": "Work Orders",
	"Service Reports": "Service Reports",
	"Backlog": "Backlog",
	"Backlog Item": "Backlog Item",
	"Backlog Description": "Backlog Description",
	"Target Inpsection Date": "Target Inpsection Date",
	"Inspection Type": "Inspection Type",
	"Technician": "Technician",
	"Approval Required": "Approval Required",

	"Hold Machine": "Hold Machine",
	"Release Machine": "Release Machine",
	"Approve": "Approve",
	"Close": "Close",

	"Area": "Area",
	"Task": "Task",
	"Value": "Value",
	"Comment": "Comment",
	"Approval": "Approval",
	"Add to Backlog": "Add to Backlog",
	"Notes": "Notes",

	"Inspection Images": "Inspection Images",
	"Task Images": "Task Images",
	"TaskID": "TaskID",
	"In Supervisor Review": "In Supervisor Review",
	"Open Inspection": "Open Inspection",
	"Recent Inspections": "Recent Inspections",
	"Add Video": "Add Video",
	"Name": "Name",

	"Item ID": "Item ID",
	"Logged Date": "Logged Date",
	"Task Id": "Task Id",
	"Add/Edit Bcklog": "Add/Edit Backlog",

	"Add All Advertisers": "Add All Advertisers",
	"Select Picture": "Select Picture"

}