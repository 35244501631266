import { makeAutoObservable, observable } from "mobx";

const LOCAL_STORAGE_KEY = '936DA01F9ABD4d9d80C702AF85C822A8';

/*eslint no-use-before-define: 0*/
class LocalStorageStore {

    loading;

    @observable defaultModule = null;

    constructor() {
        makeAutoObservable(this);
        this.loading = false;
    }

    getFullname = () => {
        var jwt = this.getToken();

        var fullName;
        try {
            // Attempt to parse the JWT user as JSON
            fullName = JSON.parse(jwt.user).FirstName + ' ' + JSON.parse(jwt.user).LastName;
          } 
        catch (error) {
            fullName = jwt.user.FirstName + ' ' + jwt.user.LastName;
        }

        return fullName;
    }

    getUserId = () => {
        var jwt = this.getToken();

        return jwt.user.UserId;
    }

    getUserName = () => {
        var jwt = this.getToken();

        return jwt.user.UserName;
    }

    getExpiryTime = () => {
        var jwt = this.getToken();

        if (jwt !== undefined)
            return jwt.exp;
        else {
            return 0;
        }
    }

    saveToken = (jwt) => {

        var slot = {
            't': jwt.access_token
        }
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(slot));
    }

    removeToken = () => {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    }

    isTokenExists = () => {
        if (localStorage.getItem(LOCAL_STORAGE_KEY) === null) {
            return false;
        }
        return true;
    }

    getToken = () => {
        var token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
        return this.parseJwt(token);
    }

    get getTokenE() {
        var token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || { t: '' };
        return token.t;
    }

    parseJwt = (token) => {

        if (token !== null && token.t.split().length >= 1) {
            var base64Url = token.t.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        }
        return { user: {}, exp: 0, roles: '' }
    }
}

const localStorageStore = new LocalStorageStore();

export default localStorageStore;