import React, { useContext } from 'react';
import { Navbar } from 'reactstrap';
import AppContext from '../../../context/Context';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { topNavbarBreakpoint } from '../../../config';
import { useHistory } from "react-router";

const NavbarTop = () => {
  const history = useHistory();

  const {
    showBurgerMenu,
    setShowBurgerMenu,
    isTopNav,
    isVertical,
    isCombo,
    navbarCollapsed,
    setNavbarCollapsed
  } = useContext(AppContext);
  const handleBurgerMenu = () => {
    isTopNav && !isCombo && setNavbarCollapsed(!navbarCollapsed);
    (isCombo || isVertical) && setShowBurgerMenu(!showBurgerMenu);
  };

  const handleLogoClick = async () => {
    await localStorage.setItem('toWelcome', true);
    await history.push({ pathname: '/welcome' });
  }

  return (
    <Navbar
      dark
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit bg-dark px-4"
      expand={isTopNav && topNavbarBreakpoint}
      style={{ minWidth: '100%', position: 'inherit' }}
    >
      <a className="cursor-pointer" onClick={handleLogoClick}>
        <div className="pt-navbar-heading" style={{ width: '200px' }}>
          <img src="logo-190x138.png" className="resize" alt="vizybility" />
        </div>
      </a>
      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
