import React, { Component } from 'react';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import SessionTracker from '../utils/sessionTracker';
import { withRouter } from "react-router";
import { toast } from 'react-toastify';

const sessionMsgId = "session-msg-id";
const sessionMsgId2 = "session2-msg-id";

const notify = (isExpired) => {
    // const args = {
    // 	message: <Message expired={isExpired} />,
    // 	description: <SessionDetails expired={isExpired} />,
    //     duration: 5,
    // 	key: 'session',
    // };
    if (isExpired) {
        toast.success(<Message expired={isExpired} />, {
            toastId: sessionMsgId
        });
    } else {
        toast.info(<Message expired={isExpired} />, {
            toastId: sessionMsgId2
        });
    }
}

reaction(
    () => SessionTracker.isSoonToExpire, () => {
        notify(false);
    }
);

reaction(
    () => SessionTracker.isExpired, () => {
        notify(true);
    }
);

@withRouter @inject("applicationStore", "sessionTracker") @observer
class Session extends Component {
    constructor(props) {
        super();
    }

    settoexpire = () => {
        this.props.sessionTracker.isSoonToExpire = true
    }
    setexpired = () => {
        this.props.sessionTracker.isExpired = true
    }

    render() {
        if (this.props.sessionTracker.isExpired) {
            const { history } = this.props;

            setTimeout(function () {
                this.props.applicationStore.logout();
                history.push('/login');
            }.bind(this), 1000);
        }

        return <span />
        //return (<div>
        //	<button onClick={this.settoexpire}>soon to expire</button>
        //	<button onClick={this.setexpired}>expire</button>
        //</div>);
    }
}

@observer
class SessionDetails extends Component {
    constructor(props) {
        super();
    }
    render() {
        if (this.props.expired) {
            return (<span></span>);
        } else {
            return (<span>{this.props.sessionTracker.expireTime}</span>);
        }
    }
}

@observer
class Message extends Component {
    constructor(props) {
        super();
    }
    render() {
        if (this.props.expired) {
            return (<div>Session has expired</div>);
        } else {
            return (<div>Session Expires Soon</div>);
        }
    }
}

export { Session, SessionDetails }
