import { makeObservable, observable, action } from 'mobx';
import env from '../../configs/config';
import $ from 'jquery';
import LocalStorageStore from './localStorageStore';
import SessionTracker from '../../utils/sessionTracker';
import AuthHeaders from '../../utils/ajaxAuthHeaders';
import { toast } from 'react-toastify';

/*eslint no-use-before-define: 0*/

class ApplicationStore {
  @observable loading = false;
  @observable accessToken = null;
  @observable xeroAccessToken = null;
  @observable defaultModule = null;
  @observable currentModule = null;
  @observable settingKey = {};

  constructor() {
    makeObservable(this);
  }

  // initData = () => {
  //    companyStore.fetchData();
  //    assetStore.fetchData();
  //}
  @action setDefaultModule = (module) => {
    this.defaultModule = module;
  };

  @action getDefaultModules = () => {
    return LocalStorageStore.defaultModule;
  };

  @action getFullname = () => {
    return LocalStorageStore.getFullname();
  };

  @action getUserId = () => {
    return LocalStorageStore.getUserId();
  };

  @action getUserName = () => {
    return LocalStorageStore.getUserName();
  };

  @action logout = () => {
    if (LocalStorageStore.isTokenExists()) {
      $(document).trigger('application-user-logout');
    }
    localStorage.removeItem('userName');
    return LocalStorageStore.removeToken();
  };

  @action isLoggedIn = () => {
    var exp = LocalStorageStore.getExpiryTime();
    var cur = Math.round(new Date().getTime()) / 1000;
    var sessionValid = exp > cur;

    if (!sessionValid) {
      this.logout();
    }
    return sessionValid;
  };

  @action isExternalLoggedIn = () => {
    return JSON.parse(localStorage.getItem('ExternalLogin'));
  };

  @action GetSigninUrl(applicationId, env) {

    const defaultUrl = env['STS_SIGNIN_URL'];
    const applicationIds = [
      '7E84E687-8B74-465F-87F4-835553B75E17',
      'D53F6593-2025-4DE3-BB6B-FD6DDE8FC774',
      '42F1B2CA-3B49-46BF-8FB9-93AE589B20A7'
    ];
  
    // Store the ApplicationId in local storage
    localStorage.setItem('ApplicationId', applicationId);

    if (applicationIds.includes(applicationId)) {
      return "https://viz-security-token-service-dev.azurewebsites.net/connect/token";
    }
  
    return defaultUrl;
  }

  @action GetAPIUrl(env) {
    
    const defaultUrl = env['WEBAPI_BASE_URL'];
    const applicationIds = [
      '7E84E687-8B74-465F-87F4-835553B75E17',
      'D53F6593-2025-4DE3-BB6B-FD6DDE8FC774',
      '42F1B2CA-3B49-46BF-8FB9-93AE589B20A7'
    ];
  
    // Store the ApplicationId in local storage
    const applicationId = localStorage.getItem('ApplicationId');

    if (applicationIds.includes(applicationId)) {
      return "https://vizybility-api-dev.azurewebsites.net";
    }
  
    return defaultUrl;
  }

  @action login = (userName, password, ApplicationId) => {
    localStorage.setItem('userName', userName);

    const url = this.GetSigninUrl(ApplicationId, env);

    var settings = {
      async: true,
      crossDomain: true,
      url: url,
      method: 'POST',
      headers: {
        'cache-control': 'no-cache',
        'Postman-Token': '57e2d224-e409-47a0-93c0-e45a0c8e34e2',
      },
      data: {
        grant_type: 'password',
        username: userName,
        password: password,
        client_id: env['STS_CLIENT_ID'],
        client_secret: env['STS_CLIENT_SECRET'],
        scope: env['STS_SCOPE'],
        ApplicationId: ApplicationId,
      },
    };

    return $.ajax(settings).done(function (data) {
      LocalStorageStore.saveToken(data);
      SessionTracker.isExpired = false;
      SessionTracker.isSoonToExpire = false;
    });
  };

  @action signInWithAD = (data, accessToken, ApplicationId) => {
    localStorage.setItem('userName', data.userPrincipalName);

   const url = this.GetSigninUrl(ApplicationId, env);

    var settings = {
      async: true,
      crossDomain: true,
      url: url,
      method: 'POST',
      headers: {
        'cache-control': 'no-cache',
        'Postman-Token': '57e2d224-e409-47a0-93c0-e45a0c8e34e2',
      },
      data: {
        grant_type: 'password',
        username: data.userPrincipalName,
        password: data.id,
        client_id: env['STS_CLIENT_ID'],
        client_secret: env['STS_CLIENT_SECRET'],
        scope: env['STS_SCOPE'],
        ApplicationId: ApplicationId,
        ExternalUserId: data.id,
        AccessToken: accessToken,
        IsExternalRequest: true,
      },
    };
    return $.ajax(settings).done(function (data) {
      LocalStorageStore.saveToken(data);
      localStorage.setItem('ExternalLogin', true);
      SessionTracker.isExpired = false;
      SessionTracker.isSoonToExpire = false;
    });
  };

  @action login3 = (userName, password) => {
    return $.ajax({
      url: env['STS_SIGNIN_URL'],
      dataType: 'application/x-www-form-urlencoded; charset=UTF-8',
      type: 'POST',
      data: {
        grant_type: 'password',
        username: userName,
        password: password,
        client_id: env['STS_CLIENT_ID'],
        client_secret: env['STS_CLIENT_SECRET'],
        scope: env['STS_SCOPE'],
        ApplicationId: env['APPLICATION_ID'],
      },
      context: this,
      success: function (data, textStatus, request) {
        LocalStorageStore.saveToken(data);
        SessionTracker.isExpired = false;
        SessionTracker.isSoonToExpire = false;
      },
    });
  };

  @action async connectDB() {

    let url = this.GetAPIUrl(env);

    return $.ajax({
      url: url + '/Finance/GetQBRedirectUrl',
      dataType: 'json',
      headers: new AuthHeaders().getToken(),
      data: {},
      context: this,
    })
      .done(async function (data) {
        // handle success
        // this.invoiceSummary = await data;
        window.location.href = data.redirectUrl;
      })
      .fail(function (error) {})
      .always(function () {
        // always executed
      });
  }

  @action async GetQBAccessToken() {
    this.loading = true;
    let url = this.GetAPIUrl(env);

    return $.ajax({
      url: url + '/Finance/QbCreateAccessToken',
      dataType: 'json',
      headers: new AuthHeaders().getToken(),
      data: {},
      context: this,
    })
      .done(async function (data) {
        this.loading = false;

        if (data && data.AccessToken && data.AccessToken !== '') {
          //todo then do not display connect button
          this.accessToken = true;
        } else {
          //display connect button
          this.accessToken = false;
        }
      })
      .fail(function (error) {
        this.accessToken = false;
        toast.error(error.responseText);

        //toster with error message
      })
      .always(function () {
        this.loading = false;
      });
  }

  @action async GetXeroToken() {
    this.loading = true;
    let url = this.GetAPIUrl(env);

    return $.ajax({
      url: url + '/Finance/XeroCreateAccessToken',
      dataType: 'json',
      headers: new AuthHeaders().getToken(),
      data: {},
      context: this,
    })
      .done(async function (data) {
        this.loading = false;

        if (data && data.AccessToken && data.AccessToken !== '') {
          //todo then do not display connect button
          this.xeroAccessToken = true;
        } else {
          //display connect button
          this.xeroAccessToken = false;
        }
      })
      .fail(function (error) {
        this.xeroAccessToken = false;
        //toster with error message
        toast.error(error.responseText);
      })
      .always(function () {
        this.loading = false;
      });
  }

  @action async connectXero() {
    let url = this.GetAPIUrl(env);

    return $.ajax({
      url: url + '/Finance/GetXeroRedirectUrl',
      dataType: 'json',
      headers: new AuthHeaders().getToken(),
      data: {},
      context: this,
    })
      .done(async function (data) {
        // handle success
        // this.invoiceSummary = await data;
        window.location.href = data.redirectUrl;
      })
      .fail(function (error) {})
      .always(function () {
        // always executed
      });
  }

  @action async ClearTokens() {
    this.loading = true;
    let url = this.GetAPIUrl(env);

    return $.ajax({
      url: url + '/Finance/ClearTokens',
      dataType: 'json',
      headers: new AuthHeaders().getToken(),
      data: {},
      context: this,
    })
      .done(async function (data) {
        if (this.currentModule?.includes('QuickBook')) {
          this.GetQBAccessToken();
        }
        if (this.currentModule?.includes('Xero')) {
          this.GetXeroToken();
        }
        this.GetCurrentModule();
        this.loading = false;
      })
      .fail(function (error) {
        toast.error(error.responseText);
      })
      .always(function () {
        // always executed
        this.loading = false;
      });
  }

  @action async GetSettingByKey(key) {
    this.loading = true;
    let url = this.GetAPIUrl(env);
    return $.ajax({
      url: url + '/Settings/GetSettingByKey?key=' + key,
      dataType: 'json',
      headers: new AuthHeaders().getToken(),
      data: {},
      context: this,
    })
      .done(async function (data) {
        this.loading = false;
        this.settingKey = data;
      })
      .fail(function (error) {
        toast.error(error);
      })
      .always(function () {
        // always executed
        this.loading = false;
      });
  }

  @action async GetCurrentModule() {
    this.loading = true;
    const currentModule = await this.GetSettingByKey('FinanceServiceName');
    this.currentModule = currentModule.SettingValue;
  }

  @action async SyncXeroInvoice() {
    this.loading = true;
    let url = this.GetAPIUrl(env);

    return $.ajax({
      url: url + '/Finance/SyncXeroInvoices',
      dataType: 'json',
      type: 'POST',
      headers: new AuthHeaders().getToken(),
      // data:  JSON.stringify({}),
      contentType: 'application/json',
      context: this,
    })
      .done(function (data) {
        // handle success
        toast.success('Invoice Sync successfully');
      })
      .fail(function (error) {
        toast.error(error.responseText);
      })
      .always(function () {
        this.loading = false;
        // always executed
      });
  }
  @action async SyncXeroContact() {
    this.loading = true;
    let url = this.GetAPIUrl(env);

    return $.ajax({
      url: url + '/Finance/SyncXeroContacts',
      dataType: 'json',
      type: 'POST',
      headers: new AuthHeaders().getToken(),
      //  data:  JSON.stringify({}),
      contentType: 'application/json',
      context: this,
    })
      .done(function (data) {
        // handle success
        toast.success('Contact Sync successfully');
      })
      .fail(function (error) {
        toast.error(error.responseText);
      })
      .always(function () {
        this.loading = false;
        // always executed
      });
  }
}

const applicationStore = new ApplicationStore();

export default applicationStore;
